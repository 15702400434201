// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Button, Heading, useTheme } from "@merit/frontend-components";
import { ConfirmationModal } from "../../components/Modals";
import { Drawer } from "../../components/Drawer";
import { FALSE_MESSAGE, MAX_RULES_PER_POLICY, TRUE_MESSAGE } from "./constants";
import { FullScreenModalLayout } from "../../layouts/FullScreenModalLayout";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../../components/Spacer";
import { MeritTemplatesList } from "./MeritTemplatesList";
import {
  GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerActionEnum as PermissionsActionEnum,
  OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomPredicateEnum as Predicate,
} from "../../gen/org-portal";
import { PolicyForm } from "./PolicyForm";
import { PolicyRule } from "./PolicyRule";
import { ScrollView, StyleSheet, View } from "react-native";
import { SelectPermissions } from "../../components/SelectPermissions";
import { Spin } from "../../components";
import { useAlertStore } from "../../stores";
import { useApi } from "../../api/api";
import { useLoadedConfigurationState } from "../../hooks/useLoadedConfigurationState";
import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import { useNavigation } from "@react-navigation/native";
import { useRef, useState } from "react";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import { useTemplatesData } from "./useTemplatesData";
import { v4 as uuidv4 } from "uuid";
import type { FormValues } from "./PolicyForm";
import type { FormikProps } from "formik";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom } from "@src/gen/org-portal";
import type { RouteParams } from "../../Router";
import type { Template } from "./MeritTemplatesList";

const { Some } = Helpers;
const SCREEN_NAME = "CreatePolicy";

type Rule = OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtom & {
  readonly id: string;
};

export type Permissions = {
  readonly extendPermission: "no" | "yes";
  readonly readPermission: "no" | "yes";
};

export const CreatePolicyScreen = () => {
  const { theme } = useTheme();
  const formRef = useRef<FormikProps<FormValues>>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentRuleID, setCurrentRuleID] = useState<string>();
  const [rules, setRules] = useState<readonly Rule[]>([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { deleteAlert, setAlert } = useAlertStore();
  const { api } = useApi();
  const { selectedOrgId } = useLoggedInAuthState();
  const { configuration } = useLoadedConfigurationState();
  const { errorHandler } = useServerErrorHandler();
  const navigation = useNavigation<NativeStackNavigationProp<RouteParams, "CreatePolicy">>();
  const scrollViewRef = useRef<ScrollView>(null);
  const [permissions, setPermissions] = useState<Permissions>({
    extendPermission: "yes",
    readPermission: "yes",
  });

  const { isLoading: isFetching, templates } = useTemplatesData(api, {
    limit: 100,
    orgID: selectedOrgId,
    status: "live",
    type: "Merit",
  });

  const styles = StyleSheet.create({
    body: {
      alignSelf: "center",
      paddingVertical: 32,
      width: 960,
    },
    centeredRow: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    divider: {
      backgroundColor: theme.colors.border.default,
      height: 1,
    },
    footer: {
      backgroundColor: theme.colors.background.white,
      borderTopColor: theme.colors.border.subdued,
      borderTopWidth: 1,
      flexDirection: "row",
      justifyContent: "flex-end",
      paddingHorizontal: 32,
      paddingVertical: theme.spacing.l,
    },
  });

  const addTemplateForRule = (template: Template) => {
    setRules(prevRules =>
      prevRules.map(rule => {
        if (rule.id === currentRuleID) {
          const templateData = rule.arguments?.find(templateID => templateID === template.id);
          if (Some(templateData)) {
            setAlert({
              closable: true,
              id: uuidv4(),
              onPressDelete: id => {
                deleteAlert(id);
              },
              testProps: {
                elementName: "addTemplateError",
                screenName: SCREEN_NAME,
              },
              text: `Template already added to the policy.`,
              type: "error",
            });

            return rule;
          }

          const updatedRule = {
            arguments: Some(rule.arguments) ? [...rule.arguments, template.id] : [template.id],
            id: rule.id,
          };
          setAlert({
            closable: true,
            id: uuidv4(),
            onPressDelete: id => {
              deleteAlert(id);
            },
            testProps: {
              elementName: "addTemplateSuccess",
              screenName: SCREEN_NAME,
            },
            text: `Template added to policy.`,
            type: "success",
          });

          return updatedRule;
        }

        return rule;
      })
    );
  };

  const closeScreen = () => {
    if ((Some(formRef.current) && formRef.current.dirty) || rules.length > 1) {
      setConfirmationModal(true);

      return;
    }

    if (navigation.canGoBack()) {
      navigation.goBack();

      return;
    }

    navigation.navigate("Policies");
  };

  const createPolicy = async (values: FormValues) => {
    try {
      setIsLoading(true);
      const readPermission = permissions.readPermission === "yes" ? "All" : "None";
      const extendPermission =
        permissions.readPermission === "yes" && permissions.extendPermission === "yes"
          ? "All"
          : "None";
      const perms = [
        {
          action: PermissionsActionEnum.Read,
          permissibleToPermit: {
            action: PermissionsActionEnum.Read,
            grantedToName: readPermission,
          },
          permitted: {
            action: PermissionsActionEnum.Read,
            grantedToName: readPermission,
          },
        },
        {
          action: PermissionsActionEnum.Extend,
          permissibleToPermit: {
            action: PermissionsActionEnum.Extend,
            grantedToName: extendPermission,
          },
          permitted: {
            action: PermissionsActionEnum.Extend,
            grantedToName: extendPermission,
          },
        },
      ];
      await api.extendPolicy({
        orgID: selectedOrgId,
        properties: {
          description: values.description.trim(),
          falseMessage: FALSE_MESSAGE,
          name: values.name.trim(),
          permissions: perms,
          rule: rules
            .filter(rule => Some(rule.arguments) && rule.arguments.length > 1)
            .map(rule => ({
              ...rule,
              id: undefined,
              predicate: Some(rule.predicate)
                ? rule.predicate
                : Predicate.ReceivedXContainersFromTemplates,
            })),
          sourcePolicyID: configuration.basePolicyUUID,
          trueMessage: TRUE_MESSAGE,
        },
      });
      setAlert({
        closable: true,
        id: uuidv4(),
        onPressDelete: id => {
          deleteAlert(id);
        },
        testProps: {
          elementName: "createPolicySuccess",
          screenName: SCREEN_NAME,
        },
        text: `New policy has been added`,
        type: "success",
      });
      setRules([]);
      navigation.navigate("Policies");
    } catch (error) {
      errorHandler(error, {
        elementName: "createPolicyError",
        screenName: SCREEN_NAME,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <FullScreenModalLayout
        onClose={() => {
          setRules([]);
          closeScreen();
        }}
        testProps={{
          elementName: "createPolicy",
          screenName: SCREEN_NAME,
        }}
        title="Create policy"
      >
        <>
          {isLoading || isFetching ? (
            <View style={{ flex: 1, justifyContent: "center" }}>
              <Spin />
            </View>
          ) : (
            <ScrollView ref={scrollViewRef} style={{ flex: 1 }}>
              <View style={styles.body}>
                <PolicyForm
                  formRef={formRef}
                  formSubmitCallback={formValues => {
                    createPolicy(formValues);
                  }}
                  testProps={{
                    elementName: "createPolicy",
                    screenName: SCREEN_NAME,
                  }}
                />

                <VerticalSpacer size={60} />
                <View style={styles.divider} />
                <VerticalSpacer size={45} />

                <>
                  <View style={styles.centeredRow}>
                    <Heading
                      bold
                      level="3"
                      testProps={{
                        elementName: "createPolicyRulesHeaderText",
                        screenName: SCREEN_NAME,
                      }}
                    >
                      Rules
                    </Heading>
                    <View style={{ width: 180 }}>
                      <Button
                        disabled={rules.length >= MAX_RULES_PER_POLICY}
                        iconLeft="addSmallDefault"
                        onPress={() => {
                          setRules(prevRules => [...prevRules, { arguments: ["1"], id: uuidv4() }]);
                          // There is a slight delay in detecting the newly added element.
                          setTimeout(() => {
                            scrollViewRef.current?.scrollToEnd();
                          }, 100);
                        }}
                        size="small"
                        testProps={{
                          elementName: "createPolicyAddRuleButton",
                          screenName: SCREEN_NAME,
                        }}
                        text="Add rule"
                        type="secondary"
                      />
                    </View>
                  </View>

                  {rules.length === 0 && (
                    <>
                      <VerticalSpacer size={20} />
                      <Body
                        testProps={{
                          elementName: "createPolicyNoRulesPlaceholder",
                          screenName: SCREEN_NAME,
                        }}
                      >
                        This policy does not have any org created rules
                      </Body>
                    </>
                  )}

                  <>
                    {rules.map((rule, index) => (
                      <PolicyRule
                        key={rule.id}
                        onPressAdditionIcon={() => {
                          setCurrentRuleID(rule.id);
                          setIsDrawerOpen(true);
                        }}
                        onRemove={() => {
                          setRules(prevRules => prevRules.filter(({ id }) => rule.id !== id));
                        }}
                        removeTemplate={templateID => {
                          setRules(prevRules =>
                            prevRules.map(prevRule => {
                              if (prevRule.id === rule.id) {
                                return {
                                  arguments: prevRule.arguments?.filter(id => id !== templateID),
                                  id: rule.id,
                                };
                              }

                              return prevRule;
                            })
                          );
                        }}
                        rule={rule}
                        ruleIndex={index}
                        templatesList={templates}
                        testProps={{
                          elementName: "createPolicy",
                          screenName: SCREEN_NAME,
                        }}
                      />
                    ))}
                  </>
                  <VerticalSpacer size={40} />
                  <View style={styles.divider} />
                  <VerticalSpacer size={40} />
                  <SelectPermissions
                    onSelectOption={(permissionKey, value) => {
                      setPermissions(prev => ({ ...prev, [permissionKey]: value }));
                    }}
                    permissionFor="policy"
                    testProps={{
                      elementName: "createPolicyPermission",
                      screenName: SCREEN_NAME,
                    }}
                    values={permissions}
                  />
                </>
              </View>
            </ScrollView>
          )}
        </>
        <View style={styles.footer}>
          <Button
            disabled={isLoading}
            onPress={closeScreen}
            testProps={{
              elementName: "createPolicyCancelButton",
              screenName: SCREEN_NAME,
            }}
            text="Cancel"
            type="secondary"
          />
          <HorizontalSpacer />
          <Button
            disabled={isLoading}
            onPress={() => formRef.current?.submitForm()}
            testProps={{
              elementName: "createPolicySaveButton",
              screenName: SCREEN_NAME,
            }}
            text="Save"
          />
        </View>
      </FullScreenModalLayout>

      <Drawer isOpen={isDrawerOpen}>
        <MeritTemplatesList
          closeDrawer={() => {
            setIsDrawerOpen(false);
          }}
          onSelect={addTemplateForRule}
          testProps={{
            elementName: "createPolicy",
            screenName: SCREEN_NAME,
          }}
        />
      </Drawer>
      {confirmationModal && (
        <ConfirmationModal
          onClose={() => {
            setConfirmationModal(false);
          }}
          onOk={() => {
            setConfirmationModal(false);
            setRules([]);
            navigation.navigate("Policies");
          }}
          testProps={{
            elementName: "createPolicyUnSavedConfirmation",
            screenName: SCREEN_NAME,
          }}
          text="Are you sure you want to leave this page? Press Cancel to go back and save the changes. You will lose all the changes you have made once you leave."
          title="Unsaved changes"
          titleIconName="warningMediumCritical"
        />
      )}
    </>
  );
};
