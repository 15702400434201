/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TemplateFieldColumnMappingProperties } from './TemplateFieldColumnMappingProperties';
import {
    TemplateFieldColumnMappingPropertiesFromJSON,
    TemplateFieldColumnMappingPropertiesFromJSONTyped,
    TemplateFieldColumnMappingPropertiesToJSON,
} from './TemplateFieldColumnMappingProperties';

/**
 * The required inputs to map a data source.
 * @export
 * @interface TemplateDataSourceMappingProperties
 */
export interface TemplateDataSourceMappingProperties {
    /**
     * 
     * @type {string}
     * @memberof TemplateDataSourceMappingProperties
     */
    dataSourceID?: string | null;
    /**
     * The mappings from column to template field.
     * @type {Array<TemplateFieldColumnMappingProperties>}
     * @memberof TemplateDataSourceMappingProperties
     */
    columnToTemplateField: Array<TemplateFieldColumnMappingProperties>;
}

/**
 * Check if a given object implements the TemplateDataSourceMappingProperties interface.
 */
export function instanceOfTemplateDataSourceMappingProperties(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "columnToTemplateField" in value;

    return isInstance;
}

export function TemplateDataSourceMappingPropertiesFromJSON(json: any): TemplateDataSourceMappingProperties {
    return TemplateDataSourceMappingPropertiesFromJSONTyped(json, false);
}

export function TemplateDataSourceMappingPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateDataSourceMappingProperties {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataSourceID': !exists(json, 'dataSourceID') ? undefined : json['dataSourceID'],
        'columnToTemplateField': ((json['columnToTemplateField'] as Array<any>).map(TemplateFieldColumnMappingPropertiesFromJSON)),
    };
}

export function TemplateDataSourceMappingPropertiesToJSON(value?: TemplateDataSourceMappingProperties | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataSourceID': value.dataSourceID,
        'columnToTemplateField': ((value.columnToTemplateField as Array<any>).map(TemplateFieldColumnMappingPropertiesToJSON)),
    };
}

