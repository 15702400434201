/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Formula } from './Formula';
import {
    FormulaFromJSON,
    FormulaFromJSONTyped,
    FormulaToJSON,
} from './Formula';

/**
 * 
 * @export
 * @interface CompoundAtom
 */
export interface CompoundAtom extends Formula {
    /**
     * 
     * @type {string}
     * @memberof CompoundAtom
     */
    target?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompoundAtom
     */
    predicate?: CompoundAtomPredicateEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompoundAtom
     */
    arguments?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CompoundAtom
     */
    errorMessage?: string;
    /**
     * 
     * @type {Formula}
     * @memberof CompoundAtom
     */
    formula?: Formula;
}


/**
 * @export
 */
export const CompoundAtomPredicateEnum = {
    AfterNow: 'AfterNow',
    AfterThisDate: 'AfterThisDate',
    AfterThisDatetime: 'AfterThisDatetime',
    AfterThisTimeOfDay: 'AfterThisTimeOfDay',
    AfterToday: 'AfterToday',
    BeforeNow: 'BeforeNow',
    BeforeNowMinusXDuration: 'BeforeNowMinusXDuration',
    BeforeThisDate: 'BeforeThisDate',
    BeforeThisDatetime: 'BeforeThisDatetime',
    BeforeThisTimeOfDay: 'BeforeThisTimeOfDay',
    BeforeToday: 'BeforeToday',
    BeforeTodayMinusXDays: 'BeforeTodayMinusXDays',
    EqualToDate: 'EqualToDate',
    EqualToX: 'EqualToX',
    FieldHasValue: 'FieldHasValue',
    IsEmailDomain: 'IsEmailDomain',
    IsFalse: 'IsFalse',
    IsTrue: 'IsTrue',
    LessThanX: 'LessThanX',
    MatchesThisString: 'MatchesThisString',
    MoreThanX: 'MoreThanX',
    MappableTo: 'MappableTo',
    ReceivedXContainersFromTemplates: 'ReceivedXContainersFromTemplates',
    ReceivedContainersFromXTemplates: 'ReceivedContainersFromXTemplates',
    ReceivedFolioFromOrg: 'ReceivedFolioFromOrg',
    ReceivedMeritFromOrg: 'ReceivedMeritFromOrg',
    SentFolioToOrg: 'SentFolioToOrg'
} as const;
export type CompoundAtomPredicateEnum = typeof CompoundAtomPredicateEnum[keyof typeof CompoundAtomPredicateEnum];


/**
 * Check if a given object implements the CompoundAtom interface.
 */
export function instanceOfCompoundAtom(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CompoundAtomFromJSON(json: any): CompoundAtom {
    return CompoundAtomFromJSONTyped(json, false);
}

export function CompoundAtomFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompoundAtom {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...FormulaFromJSONTyped(json, ignoreDiscriminator),
        'target': !exists(json, 'target') ? undefined : json['target'],
        'predicate': !exists(json, 'predicate') ? undefined : json['predicate'],
        'arguments': !exists(json, 'arguments') ? undefined : json['arguments'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
        'formula': !exists(json, 'formula') ? undefined : FormulaFromJSON(json['formula']),
    };
}

export function CompoundAtomToJSON(value?: CompoundAtom | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...FormulaToJSON(value),
        'target': value.target,
        'predicate': value.predicate,
        'arguments': value.arguments,
        'errorMessage': value.errorMessage,
        'formula': FormulaToJSON(value.formula),
    };
}

