// Copyright 2022 Merit International Inc. All Rights Reserved

import { Body, Heading, TextInput, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { OrgSelectLink } from "./OrgSelectLink";
import { PreLoginLayout } from "../../layouts/PreLoginLayout";
import { ScrollView, StyleSheet, View } from "react-native";
import { VerticalSpacer } from "../../components/Spacer";
import { useApi } from "../../api/api";
import { useAuthStore } from "../../stores/authStore";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useLoadedConfigurationState } from "../../hooks/useLoadedConfigurationState";
import { useNavigation } from "@react-navigation/native";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import React, { useEffect, useState } from "react";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { OrgOptionsGet200ResponseOrgsInner } from "../../gen/org-portal";
import type { RouteParams } from "../../Router";

const screenName = "OrgSelect";

const { None, Some } = Helpers;

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    marginBottom: 16,
    marginTop: 40,
  },
  orgsView: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
});

type Navigation = NativeStackNavigationProp<RouteParams, "LoginSuccessCallback">;

export const LegacyOrgSelectScreen = () => {
  const { theme } = useTheme();
  const { api, config } = useApi();
  const [orgs, setOrgs] = useState<readonly OrgOptionsGet200ResponseOrgsInner[]>();
  const {
    accessToken,
    profile,
    selectedOrgId,
    session,
    setSelectedOrgId,
    setSelectedOrgName,
    setSession,
  } = useAuthStore();
  const navigation = useNavigation<Navigation>();
  const [error, setError] = useState<Error | undefined>(undefined);
  const { errorHandler } = useServerErrorHandler();
  useLoadedConfigurationState(); // ensure that configuration is loaded
  const [filteredOrgs, setFilteredOrgs] = useState<readonly OrgOptionsGet200ResponseOrgsInner[]>(
    []
  );
  const client = useLDClient();
  const [hasSelectedOrg, setHasSelectedOrg] = useState<boolean>();
  const [searchValue, setSearchValue] = useState("");

  const selectOrg = async (org: OrgOptionsGet200ResponseOrgsInner) => {
    try {
      // we have to use selectOrgRaw rather than selectOrg to have access to the response headers.
      const res = await api.selectOrgRaw({ orgID: org.orgID });
      const sessionHeader = res.raw.headers.get("X-Session-Store");

      if (sessionHeader === null) {
        setError(
          new Error("Org selection failure: no session header received after selecting org.")
        );
      } else {
        setSession(sessionHeader);
        setSelectedOrgId(org.orgID);
        setSelectedOrgName(org.orgName);
        await client?.identify({
          kind: "multi",
          org: {
            key: org.orgID,
          },
          user: {
            key: profile?.entityID,
          },
        });

        setHasSelectedOrg(true);
        setSearchValue("");
        setOrgs(undefined);
      }
    } catch (err) {
      errorHandler(err);
    }
  };

  useEffect(() => {
    const getOrgsList = async () => {
      try {
        const res = await api.orgOptionsGet();

        if (None(res.orgs) || res.orgs.length <= 0) {
          navigation.navigate("NoPermissions");

          return;
        }

        const sortedOrgsByName = [...res.orgs].sort((a, b) =>
          a.orgName.toLowerCase().localeCompare(b.orgName.toLowerCase())
        );

        setOrgs(sortedOrgsByName);
        setFilteredOrgs(sortedOrgsByName);
      } catch (err) {
        errorHandler(err);
      }
    };

    if (Some(selectedOrgId)) {
      navigation.navigate("OrgSettings", { initialTab: "connectedOrganizations" });
    } else {
      getOrgsList();
    }
  }, [
    api,
    accessToken,
    navigation,
    session,
    setSession,
    config.headers,
    errorHandler,
    selectedOrgId,
  ]);

  useEffect(() => {
    if (hasSelectedOrg === true && selectedOrgId !== null) {
      navigation.navigate("OrgSettings", { initialTab: "connectedOrganizations" });
    }
  }, [hasSelectedOrg, navigation, selectedOrgId]);

  if (error !== undefined) {
    return (
      <PreLoginLayout showLogoutButton>
        <View style={styles.container}>
          <Heading level="1">{error.message}</Heading>
        </View>
      </PreLoginLayout>
    );
  }

  if (orgs === undefined) {
    return (
      <PreLoginLayout showLogoutButton>
        <View style={styles.container}>
          <Heading level="1">Loading...</Heading>
        </View>
      </PreLoginLayout>
    );
  }

  return (
    <PreLoginLayout showLogoutButton>
      <ScrollView showsVerticalScrollIndicator={false} style={{ flex: 1 }}>
        <View style={styles.container}>
          <View style={styles.orgsView}>
            <View style={{ maxWidth: 420 }}>
              <Heading
                level="1"
                numberOfLines={1}
                testProps={{
                  elementName: "title",
                  screenName,
                }}
              >
                Choose an organization
              </Heading>
              <VerticalSpacer size={theme.spacing.s} />
              <Body numberOfLines={1} size="l">
                Select which organization to log in to
              </Body>

              {Some(orgs) && orgs.length > 10 && (
                <>
                  <VerticalSpacer size={theme.spacing.xxl} />
                  <TextInput
                    leftIcon="searchSmallSubdued"
                    onChangeText={value => {
                      setSearchValue(value);
                      setFilteredOrgs(
                        orgs.filter(org => org.orgName.toLowerCase().includes(value.toLowerCase()))
                      );
                    }}
                    placeholder="Search"
                    size="large"
                    value={searchValue}
                  />
                </>
              )}
              <VerticalSpacer size={theme.spacing.xxl} />
              {filteredOrgs.length === 0 && (
                <>
                  <VerticalSpacer size={theme.spacing.l} />
                  <Heading level="3">
                    No results have been found. Check your organization name and try again.
                  </Heading>
                </>
              )}
            </View>
            {filteredOrgs.map(org => (
              <View key={org.orgID}>
                <OrgSelectLink
                  key={`org-select-link-${org.orgID}`}
                  onPress={() => {
                    selectOrg(org);
                  }}
                  org={org}
                  testProps={{
                    elementId: `${org.orgID}`,
                    elementName: "orgSelectLink",
                    screenName,
                  }}
                />
                <View key={`org-select-link-${org.orgID}-spacer`} style={{ marginBottom: 16 }} />
              </View>
            ))}
          </View>
        </View>
      </ScrollView>
    </PreLoginLayout>
  );
};
