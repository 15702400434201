// Copyright 2023 Merit International Inc. All Rights Reserved

import { ConfirmationModal } from "../../components/Modals";
import { FieldFormComponent } from "./FieldFormComponent";
import { FullScreenModalLayout } from "../../layouts/FullScreenModalLayout";
import { Helpers } from "@merit/frontend-utils";
import { NULL_UUID } from "./ExtendField";
import { Spin } from "../../components";
import { View } from "react-native";
import { useAlertStore } from "../../stores/alertStore";
import { useApi } from "../../api/api";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLoadedConfigurationState } from "../../hooks/useLoadedConfigurationState";
import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import { v4 as uuidv4 } from "uuid";
import type {
  EditFieldKindOperationRequest,
  GetFieldKind200Response as FieldKind,
  GetTemplateRules200ResponseRules as PredicateMap,
} from "../../gen/org-portal";
import type { FormValues, Option } from "./FieldFormComponent";
import type { FormikProps } from "formik";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteParams } from "../../Router";
import type { RouteProp } from "@react-navigation/native";

const { None, Some } = Helpers;

export const EditFieldScreen = () => {
  const { selectedOrgId } = useLoggedInAuthState();
  const { api } = useApi();
  const navigation = useNavigation<NativeStackNavigationProp<RouteParams>>();
  const { errorHandler } = useServerErrorHandler();
  const { params } = useRoute<RouteProp<RouteParams, "EditField">>();
  const { deleteAlert, setAlert } = useAlertStore();
  const [isLoading, setIsLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [fieldKind, setFieldKind] = useState<FieldKind>();
  const formRef = useRef<FormikProps<FormValues>>(null);
  const [baseFieldKinds, setBaseFieldKinds] = useState<readonly Option[]>([]);
  const [predicates, setPredicates] = useState<PredicateMap | undefined>(undefined);
  const [predicatesIsLoading, setPredicatesIsLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { configuration } = useLoadedConfigurationState();

  useEffect(() => {
    const fetchBaseFieldKinds = async () => {
      try {
        setIsLoading(true);
        const res = await api.getFieldKinds({
          limit: 100,
          orgID: selectedOrgId,
          ownerID: configuration.solUUID,
          parentID: NULL_UUID,
        });

        setBaseFieldKinds(
          res.fieldKinds.map(fk => ({ label: fk.dataType, value: fk.fieldKindID }))
        );
        setIsReady(true);
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBaseFieldKinds();
  }, [api, configuration.solUUID, errorHandler, params, selectedOrgId]);

  useEffect(() => {
    const fetchFieldKind = async (fieldKindID: string, orgID: string) => {
      try {
        setIsLoading(true);
        const response = await api.getFieldKind({
          fieldKindID,
          orgID,
        });

        setFieldKind(response);
        setIsReady(true);
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (Some(params) && Some(params.id)) {
      fetchFieldKind(params.id, selectedOrgId);
    }
  }, [api, selectedOrgId, params, errorHandler]);

  useEffect(() => {
    const fetchPredicates = async () => {
      try {
        setPredicatesIsLoading(true);
        const res = await api.getTemplateRules({
          orgID: selectedOrgId,
        });
        setPredicates(res.rules);
      } finally {
        setPredicatesIsLoading(false);
      }
    };
    if (!predicatesIsLoading && None(predicates)) {
      fetchPredicates();
    }
  }, [api, predicates, predicatesIsLoading, selectedOrgId]);

  const editField = useCallback(
    async (field: EditFieldKindOperationRequest) => {
      setIsLoading(true);
      try {
        await api.editFieldKind(field);

        setAlert({
          id: uuidv4(),
          onPressDelete(id: string): void {
            deleteAlert(id);
          },
          text: `Field has been updated`,
          type: "success",
        });

        navigation.navigate("Fields");
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    },
    [api, setAlert, navigation, deleteAlert, errorHandler]
  );

  const closeScreen = () => {
    if (Some(formRef.current) && formRef.current.dirty) {
      setShowConfirmationModal(true);

      return;
    }

    setIsReady(false);
    if (navigation.canGoBack()) {
      navigation.goBack();

      return;
    }

    navigation.navigate("Fields");
  };

  return (
    <>
      <FullScreenModalLayout
        onClose={closeScreen}
        title={Some(fieldKind) ? `Edit ${fieldKind.name}` : ""}
      >
        {isLoading ? (
          <View style={{ flex: 1, justifyContent: "center" }}>
            <Spin />
          </View>
        ) : (
          <>
            {Some(predicates) && isReady && Some(fieldKind) && (
              <FieldFormComponent
                baseFieldKinds={baseFieldKinds}
                formRef={formRef}
                onFormSubmit={editField}
                onPressCancel={closeScreen}
                parentFieldKind={fieldKind} // TODO: rename this param
                predicates={predicates}
                selectedOrgId={selectedOrgId}
                type="edit"
              />
            )}
          </>
        )}
      </FullScreenModalLayout>

      {showConfirmationModal && (
        <ConfirmationModal
          onClose={() => {
            setShowConfirmationModal(false);
          }}
          onOk={() => {
            if (navigation.canGoBack()) {
              navigation.goBack();

              return;
            }

            navigation.navigate("Fields");
            setShowConfirmationModal(false);
          }}
          text="Are you sure you want to leave this page? Press Cancel to go back and save the changes. You will lose all the changes you have made once you leave."
          title="Unsaved changes"
          titleIconName="warningMediumCritical"
        />
      )}
    </>
  );
};
