// Copyright 2023 Merit International Inc. All Rights Reserved

import * as Yup from "yup";
import { Body, Button, Heading, Select, TextInput, useTheme } from "@merit/frontend-components";
import { Formik } from "formik";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../../components/Spacer";
import { PreLoginLayout } from "../../layouts/PreLoginLayout";
import { ScrollView, StyleSheet, View } from "react-native";
import { Spin } from "../../components/Spin";
import { Tooltip } from "../../components/Tooltip";
import { US_STATES } from "../../constants/states";
import { isValidUSPhoneNumber } from "@src/utils/validatePhoneNumber";
import { useAlertStore } from "@src/stores";
import { useApi } from "../../api/api";
import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import { useMaskedInputProps } from "react-native-mask-input";
import { useNavigation } from "@react-navigation/native";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import { v4 as uuidv4 } from "uuid";
import React, { useRef, useState } from "react";
import type { FieldMetaProps, FormikHelpers, FormikProps } from "formik";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type {
  RegisterNewOrgRequestGoverningCountryEnum,
  RegisterNewOrgRequestGovernmentIdTypeEnum,
  RegisterNewOrgRequestTimeZoneEnum,
} from "../../gen/org-portal";
import type { RouteParams } from "../../Router";

const { None, Some } = Helpers;
const SCREEN_NAME = "OrgRegistration";
const Countries = [{ label: "United States", value: "USA" }];
const PHONE_NUMBER_PREFIX = "+1 ";
const einFormatRegEx = new RegExp("^\\d{2}-\\d{7}$", "u");

type Navigation = NativeStackNavigationProp<RouteParams, "OrgRegistration">;

const governmentIDTypes = [
  { label: "Employer Identification Number (EIN)", value: "EIN" },
  { label: "Local business license", value: "Local business license" },
];

export type FormValues = {
  readonly name: string;
  readonly description?: string;
  readonly phone: string;
  readonly email: string; // Not used, but still required by backend swagger
  readonly timeZone: RegisterNewOrgRequestTimeZoneEnum | undefined;
  readonly legalName: string;
  readonly governmentIdNumber?: string;
  readonly governmentIdType?: RegisterNewOrgRequestGovernmentIdTypeEnum;
  readonly governingCountry?: RegisterNewOrgRequestGoverningCountryEnum;
  readonly governingState?: string;
  readonly adminFirstName: string;
  readonly adminLastName: string;
  readonly adminEmail: string;
  readonly adminPhone?: string;
  readonly adminRole?: string;
};

export const timezones = [
  { label: "Hawaii", value: "Pacific/Honolulu" },
  { label: "Alaska", value: "America/Anchorage" },
  { label: "Pacific", value: "America/Los_Angeles" },
  { label: "Mountain", value: "America/Phoenix" },
  { label: "Central", value: "America/Chicago" },
  { label: "Eastern", value: "America/New_York" },
];

const defaultFormValues: FormValues = {
  adminEmail: "",
  adminFirstName: "",
  adminLastName: "",
  adminPhone: "",
  adminRole: "",
  description: "",
  email: "",
  governingCountry: "USA",
  governingState: undefined,
  governmentIdNumber: "",
  governmentIdType: undefined,
  legalName: "",
  name: "",
  phone: "",
  timeZone: undefined,
};

const isValid = (getFieldMeta: (name: string) => FieldMetaProps<string>, field: string) =>
  Some(getFieldMeta(field).error) && getFieldMeta(field).touched;

const validatePhoneNumber = (number: string | undefined) => {
  // To validate the phone number, it must have a min length of 2 chars.
  if (Some(number) && number.replace(PHONE_NUMBER_PREFIX, "").length > 2) {
    return isValidUSPhoneNumber(number);
  }

  return false;
};

const validationSchema = Yup.object().shape(
  {
    adminEmail: Yup.string()
      .trim()
      .email("Please enter a valid email")
      .required("Email is required"),
    adminFirstName: Yup.string()
      .trim()
      .min(1)
      .max(120, "First name cannot exceed 120 characters")
      .required("First name is required"),
    adminLastName: Yup.string()
      .trim()
      .min(1)
      .max(120, "Last name cannot exceed 120 characters")
      .required("Last name is required"),
    adminPhone: Yup.string()
      .optional()
      .test("validate-adminPhoneNumber", "Please enter a valid admin phone number", number =>
        Some(number) ? validatePhoneNumber(number) : true
      ),
    adminRole: Yup.string().optional().trim().max(120, "Admin role cannot exceed 120 characters"),
    description: Yup.string()
      .optional()
      .trim()
      .max(2000, "Description can't exceed 2000 characters"),
    governmentIdNumber: Yup.string().when(["governmentIdType"], {
      is: (governmentIdType: string) => governmentIdType === "Local business license",
      otherwise: Yup.string()
        .trim()
        .test("validate-EIN", "Please enter a valid Government ID number", number => {
          if (Some(number) && einFormatRegEx.test(number)) {
            return true;
          }

          return None(number);
        }),
      then: Yup.string()
        .trim()
        .min(1)
        .max(120, "Please enter a valid Government ID number")
        .required("Government ID number is required"),
    }),
    legalName: Yup.string()
      .trim()
      .min(1)
      .max(120, "Organization’s legal name cannot exceed 120 characters")
      .required("Organization’s legal name is required"),
    name: Yup.string()
      .trim()
      .min(1)
      .max(120, "Organization name cannot exceed 120 characters")
      .required("Organization name is required"),
    phone: Yup.string()
      .required("Phone number is required")
      .test("validate-phoneNumber", "Please enter a valid phone number", number =>
        validatePhoneNumber(number)
      ),
    timeZone: Yup.string().trim().required("Please select timezone"),
    website: Yup.string().optional().trim().max(120, "Please enter a valid URL"),
  },
  [["adminPhone", "adminPhone"]]
);

export const OrgRegister = () => {
  const { theme } = useTheme();
  const { api } = useApi();
  const { selectedOrgId } = useLoggedInAuthState();
  const [isLoading, setIsLoading] = useState(false);
  const navigation = useNavigation<Navigation>();
  const [keyToRerenderDropdown, setKeyToRerenderDropdown] = useState(0);
  const { errorHandler } = useServerErrorHandler();
  const { deleteAlert, setAlert } = useAlertStore();

  const formRef = useRef<FormikProps<FormValues>>(null);

  const digitsRegEx = new RegExp("^\\d", "u");

  const phoneMask = ["+", "1", " ", ...new Array(10).fill(digitsRegEx)];
  const einMask = [...new Array(2).fill(digitsRegEx), "-", ...new Array(7).fill(digitsRegEx)];

  const styles = StyleSheet.create({
    container: {
      alignSelf: "center",
      flex: 1,
      paddingVertical: 40,
      width: 900,
    },
    divider: {
      backgroundColor: theme.colors.border.subdued,
      height: 1,
    },
    fieldItem: {
      flex: 1,
      minWidth: 388,
    },
    fieldsRow: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    footer: {
      alignItems: "flex-end",
      backgroundColor: theme.colors.background.white,
      borderTopColor: theme.colors.border.subdued,
      borderTopWidth: 1,
      paddingHorizontal: 32,
      paddingVertical: theme.spacing.l,
    },
  });

  const getFieldValue = (value: string | undefined) => (value?.trim() === "" ? undefined : value);

  const getPhoneNumberWithoutPrefix = (number: string | undefined) =>
    Some(number) ? number.replace(PHONE_NUMBER_PREFIX, "") : undefined;

  const createNewOrg = async (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
    setIsLoading(true);
    const timeZone = values.timeZone;
    if (None(timeZone)) {
      return;
    }

    const formValues = {
      ...values,
      adminPhone: getPhoneNumberWithoutPrefix(getFieldValue(values.adminPhone)),
      adminRole: getFieldValue(values.adminRole),
      email: values.adminEmail,
      governmentIdNumber: getFieldValue(values.governmentIdNumber?.replace("-", "")),
      phone: getPhoneNumberWithoutPrefix(values.phone),
      timeZone,
    };

    try {
      await api.registerNewOrg({
        orgID: selectedOrgId,
        registerNewOrgRequest: formValues,
      });
      resetForm({ values: defaultFormValues });
      setKeyToRerenderDropdown(prevKey => prevKey + 1);
      navigation.navigate("OrgRegistrationSuccess");
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const displayAlert = (text: string) => {
    setAlert({
      closable: true,
      id: uuidv4(),
      onPressDelete: id => {
        deleteAlert(id);
      },
      text,
      type: "error",
    });
  };

  const orgPhoneMaskedInputProps = useMaskedInputProps({
    mask: phoneMask,
    onChangeText: value => {
      formRef.current?.setFieldValue("phone", value);
    },
  });

  const adminPhoneMaskedInputProps = useMaskedInputProps({
    mask: phoneMask,
    onChangeText: value => {
      formRef.current?.setFieldValue("adminPhone", value);
    },
  });

  const einNumberInputProps = useMaskedInputProps({
    mask: einMask,
    onChangeText: value => {
      formRef.current?.setFieldValue("governmentIdNumber", value);
    },
  });
  const localBusinessLicenseInputProps = useMaskedInputProps({
    onChangeText: value => {
      formRef.current?.setFieldValue("governmentIdNumber", value);
    },
  });

  return (
    <PreLoginLayout showHomeButton showLogoutButton>
      <ScrollView>
        <Spin spinning={isLoading}>
          <Formik
            initialValues={defaultFormValues}
            innerRef={formRef}
            onSubmit={createNewOrg}
            validationSchema={validationSchema}
          >
            {({
              errors,
              getFieldMeta,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              values,
            }) => (
              <>
                <View style={styles.container}>
                  <Heading
                    level="1"
                    numberOfLines={1}
                    testProps={{
                      elementName: "registerYourOrganizationTitle",
                      screenName: SCREEN_NAME,
                    }}
                  >
                    Register your organization
                  </Heading>
                  <VerticalSpacer size={40} />
                  <Heading
                    bold
                    level="3"
                    numberOfLines={1}
                    testProps={{
                      elementName: "organizationDetailsText",
                      screenName: SCREEN_NAME,
                    }}
                  >
                    Organization details
                  </Heading>
                  <VerticalSpacer size={7} />
                  <Body>Please enter your organization’s details below</Body>
                  <VerticalSpacer size={theme.spacing.xxl} />
                  <View style={{ zIndex: 1 }}>
                    <View style={{ width: 388 }}>
                      <TextInput
                        label="Organization’s name *"
                        onBlur={handleBlur("name")}
                        onChangeText={handleChange("name")}
                        placeholder="Organization name"
                        size="large"
                        testProps={{
                          elementName: "organizationNameTextInput",
                          screenName: SCREEN_NAME,
                        }}
                        value={values.name}
                      />
                      {isValid(getFieldMeta, "name") && (
                        <Body
                          color={theme.colors.text.alert.critical}
                          testProps={{
                            elementName: "organizationNameTextInputError",
                            screenName: SCREEN_NAME,
                          }}
                        >
                          {errors.name}
                        </Body>
                      )}
                    </View>

                    <VerticalSpacer size={theme.spacing.xxl} />

                    <>
                      <View style={styles.fieldItem}>
                        <TextInput
                          label="Description"
                          labelRightElement={<VerticalSpacer size={theme.spacing.xl} />}
                          numberOfLines={4}
                          onBlur={handleBlur("description")}
                          onChangeText={handleChange("description")}
                          placeholder="Description"
                          size="large"
                          testProps={{
                            elementName: "organizationDescriptionTextInput",
                            screenName: SCREEN_NAME,
                          }}
                          value={values.description}
                        />
                        <VerticalSpacer size={theme.spacing.s} />
                        <Heading level="6">2,000 maximum character limit</Heading>

                        {isValid(getFieldMeta, "description") && (
                          <>
                            <VerticalSpacer size={theme.spacing.s} />
                            <Body
                              color={theme.colors.text.alert.critical}
                              testProps={{
                                elementName: "organizationDescriptionTextInputError",
                                screenName: SCREEN_NAME,
                              }}
                            >
                              {errors.description}
                            </Body>
                          </>
                        )}
                      </View>
                    </>

                    <VerticalSpacer size={theme.spacing.xxl} />

                    <View style={styles.fieldsRow}>
                      <View style={styles.fieldItem}>
                        <TextInput
                          label="Organization’s phone number *"
                          {...orgPhoneMaskedInputProps}
                          onBlur={handleBlur("phone")}
                          placeholder="+1   Phone number"
                          size="large"
                          testProps={{
                            elementName: "organizationPhoneNumberTextInput",
                            screenName: SCREEN_NAME,
                          }}
                          value={values.phone}
                        />
                        {isValid(getFieldMeta, "phone") && (
                          <Body
                            color={theme.colors.text.alert.critical}
                            testProps={{
                              elementName: "organizationPhoneNumberTextInputError",
                              screenName: SCREEN_NAME,
                            }}
                          >
                            {errors.phone}
                          </Body>
                        )}
                      </View>
                      <HorizontalSpacer size={theme.spacing.xxl} />
                      <View style={{ flex: 1 }}>
                        <Select
                          getOptionValue={option => option.label}
                          key={keyToRerenderDropdown}
                          label="Preferred timezone *"
                          onSelectOption={option => {
                            setFieldValue("timeZone", option.value);
                          }}
                          options={timezones}
                          placeholder={{ label: "Select timezone", value: "" }}
                          rightElement={
                            <Tooltip
                              testProps={{
                                elementName: "preferredTimezoneSelectTooltip",
                                screenName: SCREEN_NAME,
                              }}
                              text="Timezone is used to determine when merits will expire (midnight) and how times are displayed within your org portal."
                            />
                          }
                          size="large"
                          testProps={{
                            elementName: "preferredTimezone",
                            screenName: SCREEN_NAME,
                          }}
                          usePortal={Some(errors.timeZone)}
                        />
                        {isValid(getFieldMeta, "timeZone") && (
                          <Body
                            color={theme.colors.text.alert.critical}
                            testProps={{
                              elementName: "preferredTimezoneSelectError",
                              screenName: SCREEN_NAME,
                            }}
                          >
                            {errors.timeZone}
                          </Body>
                        )}
                      </View>
                    </View>
                  </View>
                  <VerticalSpacer size={40} />
                  <View style={styles.divider} />
                  <VerticalSpacer size={40} />
                  <View>
                    <Heading
                      bold
                      level="3"
                      numberOfLines={1}
                      testProps={{
                        elementName: "governmentInformationText",
                        screenName: SCREEN_NAME,
                      }}
                    >
                      Government information
                    </Heading>
                    <VerticalSpacer size={7} />
                    <Body>Please enter your organization’s government information</Body>
                    <VerticalSpacer size={theme.spacing.xxl} />

                    <View style={styles.fieldsRow}>
                      <View style={{ flex: 1 }}>
                        <TextInput
                          label="Organization’s legal name *"
                          onBlur={handleBlur("legalName")}
                          onChangeText={handleChange("legalName")}
                          placeholder="Legal name"
                          size="large"
                          testProps={{
                            elementName: "organizationLegalNameTextInput",
                            screenName: SCREEN_NAME,
                          }}
                          value={values.legalName}
                        />
                        {isValid(getFieldMeta, "legalName") && (
                          <Body
                            color={theme.colors.text.alert.critical}
                            testProps={{
                              elementName: "organizationLegalNameTextInputError",
                              screenName: SCREEN_NAME,
                            }}
                          >
                            {errors.legalName}
                          </Body>
                        )}
                      </View>
                      <HorizontalSpacer size={theme.spacing.xxl} />
                      <View style={{ flex: 1 }} />
                    </View>

                    <VerticalSpacer size={theme.spacing.xxl} />

                    <View style={styles.fieldsRow}>
                      <View style={{ flex: 1 }}>
                        <Select
                          key={keyToRerenderDropdown}
                          label="Government ID type"
                          onSelectOption={option => {
                            setFieldValue("governmentIdType", option.value);
                            setFieldValue("governmentIdNumber", "");
                          }}
                          options={governmentIDTypes}
                          placeholder={{ label: "Government ID type", value: "" }}
                          size="large"
                          testProps={{
                            elementName: "governmentIDType",
                            screenName: SCREEN_NAME,
                          }}
                          usePortal
                        />
                        {isValid(getFieldMeta, "governmentIdType") && (
                          <Body
                            color={theme.colors.text.alert.critical}
                            testProps={{
                              elementName: "governmentIDTypeSelectError",
                              screenName: SCREEN_NAME,
                            }}
                          >
                            {errors.governmentIdType}
                          </Body>
                        )}
                      </View>
                      <HorizontalSpacer size={theme.spacing.xxl} />
                      <View style={{ flex: 1 }}>
                        <TextInput
                          disabled={None(values.governmentIdType)}
                          label="Government ID number"
                          onBlur={handleBlur("governmentIdNumber")}
                          {...(values.governmentIdType === "EIN"
                            ? einNumberInputProps
                            : localBusinessLicenseInputProps)}
                          placeholder={
                            values.governmentIdType === "EIN"
                              ? "XX-XXXXXXX"
                              : "Government ID number"
                          }
                          size="large"
                          testProps={{
                            elementName: "governmentIDNumberTextInput",
                            screenName: SCREEN_NAME,
                          }}
                          value={values.governmentIdNumber}
                        />
                        {isValid(getFieldMeta, "governmentIdNumber") && (
                          <Body
                            color={theme.colors.text.alert.critical}
                            testProps={{
                              elementName: "governmentIDNumberTextInputError",
                              screenName: SCREEN_NAME,
                            }}
                          >
                            {errors.governmentIdNumber}
                          </Body>
                        )}
                      </View>
                    </View>

                    <VerticalSpacer size={theme.spacing.xxl} />

                    <View style={styles.fieldsRow}>
                      <View style={{ flex: 1 }}>
                        <Select
                          defaultValue={Countries.find(
                            country => country.value === defaultFormValues.governingCountry
                          )}
                          disabled
                          key={keyToRerenderDropdown}
                          label="Governing country"
                          onSelectOption={option => {
                            setFieldValue("governingCountry", option.value);
                          }}
                          options={Countries}
                          size="large"
                          testProps={{
                            elementName: "governingCountry",
                            screenName: SCREEN_NAME,
                          }}
                          usePortal
                        />
                      </View>
                      <HorizontalSpacer size={theme.spacing.xxl} />
                      <View style={{ flex: 1 }}>
                        <Select
                          key={keyToRerenderDropdown}
                          label="Governing state/province"
                          onSelectOption={option => {
                            setFieldValue("governingState", option.value);
                          }}
                          options={[...US_STATES]
                            .sort((a, b) => a.localeCompare(b))
                            .map(state => ({
                              label: state,
                              value: state,
                            }))}
                          placeholder={{ label: "Select governing state", value: "" }}
                          size="large"
                          testProps={{
                            elementName: "governingState",
                            screenName: SCREEN_NAME,
                          }}
                          usePortal
                        />
                        {isValid(getFieldMeta, "governingState") && (
                          <Body
                            color={theme.colors.text.alert.critical}
                            testProps={{
                              elementName: "governingStateSelectError",
                              screenName: SCREEN_NAME,
                            }}
                          >
                            {errors.governingState}
                          </Body>
                        )}
                      </View>
                    </View>

                    <VerticalSpacer size={40} />
                  </View>
                  <View style={styles.divider} />
                  <VerticalSpacer size={40} />
                  <View>
                    <Heading
                      bold
                      level="3"
                      numberOfLines={1}
                      testProps={{
                        elementName: "YourInfoAndContactDetailsText",
                        screenName: SCREEN_NAME,
                      }}
                    >
                      Your information and contact details for verification
                    </Heading>
                    <VerticalSpacer size={7} />
                    <Body>
                      Please enter your information and contact details so our registration
                      verification team can reach out to verify your organization
                    </Body>
                    <VerticalSpacer size={theme.spacing.xxl} />

                    <View style={styles.fieldsRow}>
                      <View style={{ flex: 1, flexDirection: "row" }}>
                        <View style={{ flex: 1 }}>
                          <TextInput
                            label="First name *"
                            onBlur={handleBlur("adminFirstName")}
                            onChangeText={handleChange("adminFirstName")}
                            placeholder="First name"
                            size="large"
                            testProps={{
                              elementName: "firstNameTextInput",
                              screenName: SCREEN_NAME,
                            }}
                            value={values.adminFirstName}
                          />
                          {isValid(getFieldMeta, "adminFirstName") && (
                            <Body
                              color={theme.colors.text.alert.critical}
                              testProps={{
                                elementName: "firstNameTextInputError",
                                screenName: SCREEN_NAME,
                              }}
                            >
                              {errors.adminFirstName}
                            </Body>
                          )}
                        </View>
                        <HorizontalSpacer />
                        <View style={{ flex: 1 }}>
                          <TextInput
                            label="Last name *"
                            onBlur={handleBlur("adminLastName")}
                            onChangeText={handleChange("adminLastName")}
                            placeholder="Last name"
                            size="large"
                            testProps={{
                              elementName: "lastNameTextInput",
                              screenName: SCREEN_NAME,
                            }}
                            value={values.adminLastName}
                          />
                          {isValid(getFieldMeta, "adminLastName") && (
                            <Body
                              color={theme.colors.text.alert.critical}
                              testProps={{
                                elementName: "lastNameTextInputError",
                                screenName: SCREEN_NAME,
                              }}
                            >
                              {errors.adminLastName}
                            </Body>
                          )}
                        </View>
                      </View>

                      <HorizontalSpacer size={theme.spacing.xxl} />
                      <View style={{ flex: 1 }}>
                        <TextInput
                          label="Email *"
                          onBlur={handleBlur("adminEmail")}
                          onChangeText={handleChange("adminEmail")}
                          placeholder="Your email"
                          size="large"
                          testProps={{
                            elementName: "emailTextInput",
                            screenName: SCREEN_NAME,
                          }}
                          value={values.adminEmail}
                        />
                        {isValid(getFieldMeta, "adminEmail") && (
                          <Body
                            color={theme.colors.text.alert.critical}
                            testProps={{
                              elementName: "emailTextInputError",
                              screenName: SCREEN_NAME,
                            }}
                          >
                            {errors.adminEmail}
                          </Body>
                        )}
                      </View>
                    </View>

                    <VerticalSpacer size={theme.spacing.xxl} />
                    <View style={styles.fieldsRow}>
                      <View style={{ flex: 1 }}>
                        <TextInput
                          label="Admin phone number"
                          labelRightElement={
                            <Tooltip
                              testProps={{
                                elementName: "verificationContactNumberTextInputTooltip",
                                screenName: SCREEN_NAME,
                              }}
                              text="This is the number our team will be contacting to verify your organization"
                            />
                          }
                          {...adminPhoneMaskedInputProps}
                          onBlur={handleBlur("adminPhone")}
                          placeholder="+1  Phone number"
                          size="large"
                          testProps={{
                            elementName: "verificationContactNumberTextInput",
                            screenName: SCREEN_NAME,
                          }}
                          value={values.adminPhone}
                        />
                        {isValid(getFieldMeta, "adminPhone") && (
                          <Body
                            color={theme.colors.text.alert.critical}
                            testProps={{
                              elementName: "verificationContactNumberTextInputError",
                              screenName: SCREEN_NAME,
                            }}
                          >
                            {errors.adminPhone}
                          </Body>
                        )}
                      </View>
                      <HorizontalSpacer size={theme.spacing.xxl} />
                      <View style={{ flex: 1 }} />
                    </View>

                    <VerticalSpacer size={theme.spacing.xxl} />
                  </View>
                </View>
                <View style={styles.footer}>
                  <Button
                    onPress={() => {
                      handleSubmit();
                      Object.values(errors).forEach(error => {
                        displayAlert(error);
                      });
                    }}
                    testProps={{
                      elementName: "registerButton",
                      screenName: SCREEN_NAME,
                    }}
                    text="Register"
                  />
                </View>
              </>
            )}
          </Formik>
        </Spin>
      </ScrollView>
    </PreLoginLayout>
  );
};
