// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Heading, Modal, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../../components/Spacer";
import { Image, Pressable, ScrollView, StyleSheet, View } from "react-native";
import { Images } from "../../utils/Image";
import { Spin, Tooltip } from "../../components";
import { UploadFileModal } from "./UploadFileModal";
import { getContainerFieldValue } from "../../utils/getContainerFieldValue";
import { getDateTimeString } from "../../utils/time";
import { timezones } from "../OrgRegistration/OrgRegistration";
import { uploadImage } from "../../utils/uploadImage";
import { useAlertStore } from "../../stores/alertStore";
import { useApi } from "../../api/api";
import { useAppConstantsStore } from "../../stores";
import { useIsFocused } from "@react-navigation/native";
import { useLoadedConfigurationState } from "../../hooks/useLoadedConfigurationState";
import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import { useOrgLogo } from "../../hooks/useOrgLogo";
import { useOrgLogoStore } from "../../stores/orgLogoStore";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import { v4 as uuidv4 } from "uuid";
import React, { useCallback, useEffect, useState } from "react";
import type * as ExpoDocumentPicker from "expo-document-picker";

export type OrgProfileValues = {
  readonly name?: string;
  readonly createdAt?: string;
  readonly description?: string;
  readonly phone?: string;
  readonly timeZone?: string;
  readonly logo?: string;
  readonly governmentIDType?: string;
  readonly governmentIDNumber?: string;
  readonly governingCountry?: string;
  readonly governingState?: string;
  readonly governmentLegalName?: string;
  readonly orgId: string;
};

const { None, Some, generateTestIdProps } = Helpers;
const SCREEN_NAME = "OrganizationSettings";

export const OrganizationProfile = () => {
  const { theme } = useTheme();
  const { selectedOrgId } = useLoggedInAuthState();
  const { api, config } = useApi();
  const { errorHandler } = useServerErrorHandler();
  const { configuration } = useLoadedConfigurationState();
  const { accountFolioFieldNames } = useAppConstantsStore();

  const [orgProfileData, setOrgProfileData] = useState<OrgProfileValues>();
  const [isLoading, setIsLoading] = useState(false);
  const [isLogoLoading, setIsLogoLoading] = useState(false);
  const [isHoverOnIcon, setIsHoverOnIcon] = useState(false);
  const { deleteAlert, setAlert } = useAlertStore();
  const isFocused = useIsFocused();

  // instead of maintaining a separate org logo state for this page
  // just update the actual `orgLogoStore` and refresh the `useOrgLogo` hook on upload
  const { orgLogo, refreshOrgLogo } = useOrgLogo();
  const { setOrgLogo } = useOrgLogoStore();

  const [showUploadModal, setShowUploadModal] = useState(false);

  const styles = StyleSheet.create({
    container: {
      alignSelf: "flex-start",
      width: 960,
    },
    divider: {
      backgroundColor: theme.colors.border.default,
      height: 1,
      paddingHorizontal: theme.spacing.xxl,
    },
    fieldContainer: { alignItems: "center", flexDirection: "row" },
    fieldInputContainer: {
      flex: 1,
      paddingHorizontal: theme.spacing.l,
      paddingVertical: theme.spacing.l, // As per vinson comment using the closet one for paddingTop.
    },
    governmentalFields: {
      flex: 1,
      flexDirection: "row",
      maxWidth: 240,
      paddingHorizontal: 32,
      paddingVertical: 26,
    },
    icon: {
      height: 24,
      left: 20,
      position: "absolute",
      resizeMode: "contain",
      top: 20,
      width: 24,
    },
    label: {
      flex: 1,
      flexDirection: "row",
      maxWidth: 240,
      paddingHorizontal: 32,
      paddingVertical: 26,
    },
    nonEditablefield: {
      backgroundColor: theme.colors.surface.disabled,
      borderColor: theme.colors.border.action.disabled,
      borderRadius: theme.spacing.xs,
      borderWidth: 1,
      color: theme.colors.icon.disabled,
      flex: 1,
      justifyContent: "center",
      maxWidth: 456,
      minHeight: 40,
      paddingHorizontal: theme.spacing.m,
      paddingVertical: theme.spacing.s,
    },
    profileImage: {
      height: 64,
      resizeMode: "contain",
      width: 64,
    },
  });

  const getOrgDetails = useCallback(async () => {
    if (
      selectedOrgId !== configuration.solUUID &&
      Some(config.headers) &&
      Object.keys(config.headers).length > 0
    ) {
      try {
        setIsLoading(true);
        const response = await api.getOrgDetails({ orgID: selectedOrgId });

        if (Some(response.org.fields)) {
          const { org } = response;

          const phoneNumber = getContainerFieldValue(accountFolioFieldNames.phoneNumber, org);
          const formattedOrgProfileData: OrgProfileValues = {
            createdAt: response.org.createdAt,
            description: getContainerFieldValue(accountFolioFieldNames.description, org),
            governingCountry: getContainerFieldValue(accountFolioFieldNames.governingCountry, org),
            governingState: getContainerFieldValue(accountFolioFieldNames.governingState, org),
            governmentIDNumber: getContainerFieldValue(
              accountFolioFieldNames.governmentIDNumber,
              org
            ),
            governmentIDType: getContainerFieldValue(accountFolioFieldNames.governmentIDType, org),
            governmentLegalName: getContainerFieldValue(
              accountFolioFieldNames.governmentLegalName,
              org
            ),
            name: getContainerFieldValue(accountFolioFieldNames.name, org),
            orgId: Some(org.recipient) ? org.recipient.id : "",
            phone: Some(phoneNumber) ? `+1 ${phoneNumber}` : "",
            timeZone: getContainerFieldValue(accountFolioFieldNames.timeZone, org),
          };

          setOrgProfileData(formattedOrgProfileData);
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [
    selectedOrgId,
    configuration.solUUID,
    config.headers,
    api,
    accountFolioFieldNames,
    errorHandler,
  ]);

  useEffect(() => {
    if (isFocused) {
      getOrgDetails();
    }
  }, [getOrgDetails, isFocused]);

  if (isLoading) {
    return (
      <View
        style={{
          alignItems: "center",
          flex: 1,
          justifyContent: "center",
        }}
      >
        <Spin />
      </View>
    );
  }

  if (None(orgProfileData)) {
    return null;
  }

  const uploadProfileImage = async (file: ExpoDocumentPicker.DocumentPickerAsset) => {
    if (Some(file.mimeType)) {
      setIsLogoLoading(true);
      try {
        const response = await api.getOrgDetails({ orgID: selectedOrgId });
        const res = await uploadImage(file.uri, response.logoUploadURL, file.mimeType);
        if (res.status === 200) {
          setAlert({
            closable: true,
            id: uuidv4(),
            onPressDelete: id => {
              deleteAlert(id);
            },
            testProps: {
              elementName: "orgProfileUpdateSuccess",
              screenName: SCREEN_NAME,
            },
            text: "Profile has been saved.",
            type: "success",
          });
          // set org logo in persisted state to uploaded file data
          // so the user sees their new logo, even if the CDN cache might still show the stale logo
          setOrgLogo(selectedOrgId, file.uri);
          // force the logo to be refreshed
          refreshOrgLogo();
        }
      } catch (error) {
        errorHandler(error, {
          elementName: "orgProfileUpdateError",
          screenName: SCREEN_NAME,
        });
      } finally {
        setIsLogoLoading(false);
      }
    }
  };

  return (
    <>
      <ScrollView showsVerticalScrollIndicator={false}>
        <VerticalSpacer size={theme.spacing.xxl} />
        <View style={{ paddingHorizontal: 32 }}>
          <Body
            testProps={{
              elementName: "orgProfileImageText",
              screenName: SCREEN_NAME,
            }}
          >
            Profile image
          </Body>
          <VerticalSpacer size={theme.spacing.s} />
          <View style={{ alignItems: "center", flexDirection: "row" }}>
            {isLogoLoading ? (
              <View style={{ justifyContent: "center", minHeight: 64 }}>
                <Spin />
              </View>
            ) : (
              <Pressable
                onHoverIn={() => {
                  setIsHoverOnIcon(true);
                }}
                onHoverOut={() => {
                  setIsHoverOnIcon(false);
                }}
                onPress={() => {
                  setShowUploadModal(true);
                }}
              >
                <Image
                  defaultSource={{ uri: Images.defaultProfile }}
                  source={Some(orgLogo) ? orgLogo : Images.defaultProfile}
                  style={styles.profileImage}
                  {...generateTestIdProps({
                    elementName: "orgProfileImage",
                    screenName: SCREEN_NAME,
                  })}
                />
                <Image
                  source={isHoverOnIcon ? Images.cameraOnHover : Images.camera}
                  {...generateTestIdProps({
                    elementName: "orgProfileHoverImage",
                    screenName: SCREEN_NAME,
                  })}
                  style={styles.icon}
                />
              </Pressable>
            )}

            <HorizontalSpacer size={theme.spacing.xxl} />
            <View>
              <Body
                testProps={{
                  elementName: "createdOnLabel",
                  screenName: SCREEN_NAME,
                }}
              >
                Created On
              </Body>
              <Body
                numberOfLines={1}
                testProps={{
                  elementName: "createdOn",
                  screenName: SCREEN_NAME,
                }}
              >
                {Some(orgProfileData) && Some(orgProfileData.createdAt)
                  ? getDateTimeString(orgProfileData.createdAt)
                  : "--"}
              </Body>
            </View>
          </View>
        </View>
        <VerticalSpacer size={theme.spacing.xxl} />

        <>
          <View style={styles.container}>
            <View style={styles.fieldContainer}>
              <View style={styles.label}>
                <Body
                  style={{ fontWeight: theme.fontWeights.semiBold }}
                  testProps={{
                    elementName: "nameLabel",
                    screenName: SCREEN_NAME,
                  }}
                >
                  Name*
                </Body>
                <HorizontalSpacer size={theme.spacing.s} />
                <Tooltip text="This is the name that will be displayed on your merits" />
                <HorizontalSpacer size={11} />
              </View>
              <View style={styles.fieldInputContainer}>
                <View style={styles.nonEditablefield}>
                  <Body
                    numberOfLines={1}
                    testProps={{
                      elementName: "name",
                      screenName: SCREEN_NAME,
                    }}
                  >
                    {orgProfileData.name}
                  </Body>
                </View>
              </View>
            </View>

            <View style={[styles.fieldContainer, { minHeight: 132 }]}>
              <View style={[styles.label, { alignItems: "flex-start" }]}>
                <Body
                  style={{ fontWeight: theme.fontWeights.semiBold }}
                  testProps={{
                    elementName: "descriptionLabel",
                    screenName: SCREEN_NAME,
                  }}
                >
                  Description
                </Body>
              </View>
              <View style={styles.fieldInputContainer}>
                <View
                  style={[
                    styles.nonEditablefield,
                    { justifyContent: "flex-start", minHeight: 100 },
                  ]}
                >
                  <Body
                    numberOfLines={5}
                    testProps={{
                      elementName: "description",
                      screenName: SCREEN_NAME,
                    }}
                  >
                    {orgProfileData.description}
                  </Body>
                </View>
              </View>
            </View>

            <View style={styles.fieldContainer}>
              <View style={[styles.label, { alignItems: "flex-start" }]}>
                <Body
                  style={{ fontWeight: theme.fontWeights.semiBold }}
                  testProps={{
                    elementName: "organizationIdLabel",
                    screenName: SCREEN_NAME,
                  }}
                >
                  Organization ID
                </Body>
              </View>
              <View style={styles.fieldInputContainer}>
                <View style={styles.nonEditablefield}>
                  <Body
                    numberOfLines={1}
                    testProps={{
                      elementName: "organizationId",
                      screenName: SCREEN_NAME,
                    }}
                  >
                    {orgProfileData.orgId}
                  </Body>
                </View>
              </View>
            </View>

            <View style={styles.fieldContainer}>
              <View style={styles.label}>
                <Body
                  style={{ fontWeight: theme.fontWeights.semiBold }}
                  testProps={{
                    elementName: "phoneNumberLabel",
                    screenName: SCREEN_NAME,
                  }}
                >
                  Phone number
                </Body>
              </View>
              <View style={styles.fieldInputContainer}>
                <View style={styles.nonEditablefield}>
                  <Body
                    numberOfLines={1}
                    testProps={{
                      elementName: "phoneNumber",
                      screenName: SCREEN_NAME,
                    }}
                  >
                    {orgProfileData.phone}
                  </Body>
                </View>
              </View>
            </View>

            <View style={styles.fieldContainer}>
              <View style={styles.label}>
                <Body
                  style={{ fontWeight: theme.fontWeights.semiBold }}
                  testProps={{
                    elementName: "preferredTimezoneLabel",
                    screenName: SCREEN_NAME,
                  }}
                >
                  Preferred timezone *
                </Body>
                <HorizontalSpacer size={theme.spacing.s} />
                <Tooltip text="Timezone is used to determine when merits will expire (midnight) and how times are displayed within your org portal." />
                <HorizontalSpacer size={11} />
              </View>
              <View style={styles.fieldInputContainer}>
                <View style={styles.nonEditablefield}>
                  <Body
                    numberOfLines={1}
                    testProps={{
                      elementName: "preferredTimezone",
                      screenName: SCREEN_NAME,
                    }}
                  >
                    {timezones.find(timezone => timezone.value === orgProfileData.timeZone)?.label}
                  </Body>
                </View>
              </View>
            </View>
          </View>

          <VerticalSpacer />

          <View style={styles.divider} />

          <VerticalSpacer size={40} />

          <View>
            <View style={{ paddingHorizontal: 32 }}>
              <Heading
                bold
                level="3"
                testProps={{
                  elementName: "governmentInformationHeader",
                  screenName: SCREEN_NAME,
                }}
              >
                Government information
              </Heading>
            </View>

            <VerticalSpacer size={theme.spacing.xxl} />

            <View style={styles.fieldContainer}>
              <View style={styles.governmentalFields}>
                <Body
                  style={{ fontWeight: theme.fontWeights.semiBold }}
                  testProps={{
                    elementName: "organizationLegalNameLabel",
                    screenName: SCREEN_NAME,
                  }}
                >
                  Organization’s legal name *
                </Body>
              </View>
              <View style={styles.fieldInputContainer}>
                <View style={styles.nonEditablefield}>
                  <Body
                    numberOfLines={1}
                    testProps={{
                      elementName: "organizationLegalName",
                      screenName: SCREEN_NAME,
                    }}
                  >
                    {orgProfileData.governmentLegalName}
                  </Body>
                </View>
              </View>
            </View>

            <View style={styles.fieldContainer}>
              <View style={styles.governmentalFields}>
                <Body
                  style={{ fontWeight: theme.fontWeights.semiBold }}
                  testProps={{
                    elementName: "governmentIDTypeLabel",
                    screenName: SCREEN_NAME,
                  }}
                >
                  Government ID type
                </Body>
              </View>
              <View style={styles.fieldInputContainer}>
                <View style={styles.nonEditablefield}>
                  <Body
                    numberOfLines={1}
                    testProps={{
                      elementName: "governmentIdType",
                      screenName: SCREEN_NAME,
                    }}
                  >
                    {orgProfileData.governmentIDType}
                  </Body>
                </View>
              </View>
            </View>

            <View style={styles.fieldContainer}>
              <View style={styles.governmentalFields}>
                <Body
                  style={{ fontWeight: theme.fontWeights.semiBold }}
                  testProps={{
                    elementName: "governmentIdNumberLabel",
                    screenName: SCREEN_NAME,
                  }}
                >
                  Government ID number
                </Body>
              </View>
              <View style={styles.fieldInputContainer}>
                <View style={styles.nonEditablefield}>
                  <Body
                    numberOfLines={1}
                    testProps={{
                      elementName: "governmentIdNumber",
                      screenName: SCREEN_NAME,
                    }}
                  >
                    {orgProfileData.governmentIDNumber}
                  </Body>
                </View>
              </View>
            </View>

            <View style={styles.fieldContainer}>
              <View style={styles.governmentalFields}>
                <Body
                  style={{ fontWeight: theme.fontWeights.semiBold }}
                  testProps={{
                    elementName: "governingCountryLabel",
                    screenName: SCREEN_NAME,
                  }}
                >
                  Governing country
                </Body>
              </View>
              <View style={styles.fieldInputContainer}>
                <View style={styles.nonEditablefield}>
                  <Body
                    numberOfLines={1}
                    testProps={{
                      elementName: "governingCountry",
                      screenName: SCREEN_NAME,
                    }}
                  >
                    {orgProfileData.governingCountry}
                  </Body>
                </View>
              </View>
            </View>

            <View style={styles.fieldContainer}>
              <View style={styles.governmentalFields}>
                <Body
                  style={{ fontWeight: theme.fontWeights.semiBold }}
                  testProps={{
                    elementName: "governingStateLabel",
                    screenName: SCREEN_NAME,
                  }}
                >
                  Governing state/province
                </Body>
              </View>
              <View style={styles.fieldInputContainer}>
                <View style={styles.nonEditablefield}>
                  <Body
                    numberOfLines={1}
                    testProps={{
                      elementName: "governingState",
                      screenName: SCREEN_NAME,
                    }}
                  >
                    {orgProfileData.governingState}
                  </Body>
                </View>
              </View>
            </View>

            <VerticalSpacer size={84} />
          </View>
        </>
      </ScrollView>

      {showUploadModal && (
        <Modal
          onClose={() => {
            setShowUploadModal(false);
          }}
          testProps={{
            elementName: "imageUpload",
            screenName: SCREEN_NAME,
          }}
          title="Upload"
        >
          <UploadFileModal
            onCancel={() => {
              setShowUploadModal(false);
            }}
            onError={(errMsg: string) => {
              setShowUploadModal(false);
              setAlert({
                closable: true,
                id: uuidv4(),
                onPressDelete: id => {
                  deleteAlert(id);
                },
                testProps: {
                  elementName: "imageUploadError",
                  screenName: SCREEN_NAME,
                },
                text: errMsg,
                type: "error",
              });
            }}
            onSave={file => {
              setShowUploadModal(false);
              uploadProfileImage(file);
            }}
            testProps={{ elementName: "imageUpload", screenName: SCREEN_NAME }}
          />
        </Modal>
      )}
    </>
  );
};
