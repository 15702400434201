// Copyright 2024 Merit International Inc. All Rights Reserved
/* eslint-disable react/no-multi-comp */

import { Button, Checkbox, Modal, useTheme } from "@merit/frontend-components";
import { DatasourceDetails } from "./DatasourceDetails/DatasourceDetails";
import { DatasourcesDatagridList } from "@src/screens/Datasources/DatasourcesDatagridList";
import { DatasourcesDatagridSearch } from "@src/screens/Datasources/DatasourcesDatagridSearch";
import { Drawer } from "../../components/Drawer";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../../components/Spacer";
import { Platform, StyleSheet, View } from "react-native";
import { SearchForm } from "@src/components/SearchForm/SearchForm";
import { Spin } from "@src/components";
import { UploadFileModal } from "@src/components/UploadFileModal";
import { UploadFileModalDropzone } from "../../components/UploadFileModalDropzone";
import { useAlertStore } from "@src/stores";
import { useApi } from "../../api/api";
import { useDatasourceUpload } from "../../screens/Datasources/useDatasourceUpload";
import { useFlaggedLayout } from "@src/hooks/useFlaggedLayout";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import { useNavigation, useRoute } from "@react-navigation/native";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useState } from "react";
import type { LDFeatureFlags } from "@src/configuration/featureFlags";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteParams } from "../../Router";
import type { RouteProp } from "@react-navigation/native";

const { None, Some } = Helpers;

export const SCREEN_NAME = "Datasources";

export const DatasourcesScreen = () => {
  const { theme } = useTheme();
  const { selectedOrgId } = useLoggedInAuthState();
  const { api } = useApi();
  const navigation = useNavigation<NativeStackNavigationProp<RouteParams, "Datasources">>();
  const [showHidden, setShowHidden] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const datasourceUpload = useDatasourceUpload(api, selectedOrgId);
  const { DefaultLayout } = useFlaggedLayout();
  const [datasourceId, setDatasourceId] = useState<string>();
  const [resultsCount, setResultsCount] = useState<number | undefined>(undefined);
  const [correlationId, setCorrelationId] = useState<string>();
  const [showUploadModal, setShowUploadModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [uploadModalError, setUploadModalError] = useState<Error>();
  const { showHideDatasourceFeature } = useFlags<LDFeatureFlags>();
  const { deleteAlert, setAlert } = useAlertStore();

  const [isLoading, setIsLoading] = useState(false);
  const isPlatformWeb = Platform.OS === "web";
  const [refreshDatagrid, setRefreshDatagrid] = useState<boolean>(false);
  const { params: routeParams } = useRoute<RouteProp<RouteParams, "DatasourceDetails">>();

  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
      flexDirection: "column",
    },
    loaderContainer: {
      justifyContent: "center",
      minHeight: 200,
      minWidth: 456,
    },
  });

  const createNewDatasource = () => {
    navigation.navigate("CreateDatasource");
  };

  useEffect(() => {
    if (Some(routeParams) && Some(routeParams.id)) {
      setDatasourceId(routeParams.id);
    }
  }, [routeParams]);

  useEffect(() => {
    if (resultsCount !== undefined && resultsCount >= 500) {
      setAlert({
        closable: true,
        id: uuidv4(),
        onPressDelete: id => {
          deleteAlert(id);
        },
        size: "medium",
        testProps: {
          elementName: "tooManyRecordsInSearch",
          screenName: SCREEN_NAME,
        },
        text: `More than 500 records found, please refine your search.`,
        type: "warning",
      });
    }
  }, [deleteAlert, resultsCount, setAlert]);

  const uploadFile = async (fileTextPromise: Promise<string>, fileName: string) => {
    if (None(datasourceId)) {
      throw new Error("Somehow data source not found");
    }
    try {
      setIsLoading(true);
      const fileText = await fileTextPromise;
      const cId = await datasourceUpload.upload(fileName, fileText, datasourceId);
      setShowUploadModal(false);
      setCorrelationId(cId);
      setAlert({
        closable: true,
        id: uuidv4(),
        onPressDelete: id => {
          deleteAlert(id);
        },
        text: "File uploaded successfully",
        type: "success",
      });
      setDatasourceId(undefined);
      setRefreshDatagrid(true);
    } catch (err) {
      setUploadModalError(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <DefaultLayout
        breadcrumbs={[{ name: "Create & Configure" }, { name: "Data Sources" }]}
        headerRightElements={
          <>
            {showHideDatasourceFeature && (
              <SearchForm
                disabled={showHidden}
                onClear={() => {
                  setSearchQuery(undefined);
                }}
                onSearch={value => {
                  setRefreshDatagrid(true);
                  setSearchQuery(value);
                }}
              />
            )}
            <View style={{ alignItems: "flex-start", flexDirection: "row" }}>
              <View style={{ alignItems: "center", flexDirection: "row" }}>
                <Checkbox
                  defaultChecked={showHidden}
                  label="Show hidden"
                  onChange={setShowHidden}
                />
                <HorizontalSpacer size={16} />
                <Button
                  onPress={createNewDatasource}
                  size="small"
                  testProps={{
                    elementName: "createDatasourceButton",
                    screenName: SCREEN_NAME,
                  }}
                  text="Create data source"
                />
              </View>
            </View>
          </>
        }
        title="Data Sources"
      >
        <View style={styles.container}>
          <VerticalSpacer size={theme.spacing.xxl} />
          {Some(searchQuery) && !showHidden ? (
            <>
              <DatasourcesDatagridSearch
                createNewDatasource={createNewDatasource}
                objectName={searchQuery}
                refresh={refreshDatagrid}
                setDatasourceId={setDatasourceId}
                setRefresh={setRefreshDatagrid}
                setResultsCount={setResultsCount}
              />
            </>
          ) : (
            <DatasourcesDatagridList
              createNewDatasource={createNewDatasource}
              refresh={refreshDatagrid}
              setDatasourceId={setDatasourceId}
              setRefresh={setRefreshDatagrid}
              showHidden={showHidden}
            />
          )}
        </View>
      </DefaultLayout>

      <View>
        <Drawer isOpen={Some(datasourceId)}>
          <DatasourceDetails
            correlationId={correlationId}
            datasourceId={datasourceId}
            onDrawerClose={shouldRefresh => {
              setDatasourceId(undefined);
              setCorrelationId(undefined);
              if (Some(shouldRefresh) && shouldRefresh) {
                setRefreshDatagrid(true);
              }
            }}
            onPressEdit={() => {
              if (datasourceId === undefined) {
                return;
              }

              setDatasourceId(undefined);
              navigation.navigate("UpdateDatasource", { id: datasourceId });
            }}
            onPressUpload={() => {
              setShowUploadModal(true);
            }}
            testProps={{
              elementName: "",
              screenName: SCREEN_NAME,
            }}
          />

          {showUploadModal && (
            <Modal
              onClose={() => {
                setShowUploadModal(false);
              }}
              title="Upload"
            >
              {isLoading ? (
                <View style={styles.loaderContainer}>
                  <Spin />
                </View>
              ) : (
                <>
                  {isPlatformWeb ? (
                    <UploadFileModalDropzone
                      acceptedFileTypes={{ "text/csv": [] }}
                      onCancel={() => {
                        setShowUploadModal(false);
                      }}
                      onSave={selectedFile => {
                        const fileTextPromise = selectedFile.text();
                        uploadFile(fileTextPromise, selectedFile.name);
                      }}
                    />
                  ) : (
                    <UploadFileModal
                      fileTypeName="csv"
                      fileTypes={["text/csv"]}
                      onCancel={() => {
                        setShowUploadModal(false);
                      }}
                      onSave={selectedFile => {
                        const fileTextPromise = selectedFile.file?.text();
                        if (fileTextPromise === undefined) {
                          setUploadModalError(new Error("Error loading file."));

                          return;
                        }
                        uploadFile(fileTextPromise, selectedFile.name);
                      }}
                      validFileTypes={["csv"]}
                    />
                  )}
                </>
              )}
            </Modal>
          )}
        </Drawer>
      </View>
    </>
  );
};
