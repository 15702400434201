// Copyright 2022 Merit International Inc. All Rights Reserved.

import { AssertIsDefined, AssertIsString } from "../utils/asserts";
import { useAuthStore } from "../stores/authStore";
import { useFlags } from "launchdarkly-react-client-sdk";
import type { JwtPayload } from "jwt-decode";
import type { LDFeatureFlags } from "../configuration/featureFlags";

export type JwtClaims = JwtPayload & {
  readonly name: string;
  readonly nickname: string;
  readonly entityID: string;
};

export type AuthState = {
  readonly userCanRegisterOrgs: boolean;
  readonly selectedOrgId: string;
  readonly selectedOrgName: string;
  readonly accessToken: string;
  readonly permissions: readonly string[] | null;
  readonly session: string;
  readonly profile: JwtClaims;
};

export const useLoggedInAuthState = (): AuthState => {
  const { accessToken, permissions, profile, selectedOrgId, selectedOrgName, session } =
    useAuthStore();
  const { rolloutAdminPortalAuth0OrgAuth } = useFlags<LDFeatureFlags>();

  AssertIsString(accessToken, "Access token must be a string");
  AssertIsDefined(profile, "Profile must not be null");
  if (rolloutAdminPortalAuth0OrgAuth) {
    AssertIsDefined(permissions, "Permissions must not be null");
  }

  AssertIsString(selectedOrgId, "Selected org id must not be null");
  AssertIsString(selectedOrgName, "Selected org name must not be null");
  AssertIsString(session, "Session must not be null");

  const userCanRegisterOrgs = profile.name.includes("@gomerits.com");

  return {
    accessToken,
    permissions,
    profile,
    selectedOrgId,
    selectedOrgName,
    session,
    userCanRegisterOrgs,
  };
};
