/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PermissionPair } from './PermissionPair';
import {
    PermissionPairFromJSON,
    PermissionPairFromJSONTyped,
    PermissionPairToJSON,
} from './PermissionPair';
import type { TemplateFieldModification } from './TemplateFieldModification';
import {
    TemplateFieldModificationFromJSON,
    TemplateFieldModificationFromJSONTyped,
    TemplateFieldModificationToJSON,
} from './TemplateFieldModification';
import type { TemplatePatchRules } from './TemplatePatchRules';
import {
    TemplatePatchRulesFromJSON,
    TemplatePatchRulesFromJSONTyped,
    TemplatePatchRulesToJSON,
} from './TemplatePatchRules';

/**
 * The required inputs to updating a template.
 * @export
 * @interface TemplatePatch
 */
export interface TemplatePatch {
    /**
     * The new name for a template.
     * @type {string}
     * @memberof TemplatePatch
     */
    name?: string | null;
    /**
     * The new description for a template. Since this is an optional field, if the field is missing or set to null, then the field will remain unchanged. If the field is set to empty string, then the new value would be empty string.
     * @type {string}
     * @memberof TemplatePatch
     */
    description?: string | null;
    /**
     * Whether the template is paused or live. Dependent containers will not update while a template is paused
     * @type {string}
     * @memberof TemplatePatch
     */
    state?: TemplatePatchStateEnum | null;
    /**
     * 
     * @type {Array<PermissionPair>}
     * @memberof TemplatePatch
     */
    permissions?: Array<PermissionPair>;
    /**
     * 
     * @type {Array<TemplateFieldModification>}
     * @memberof TemplatePatch
     */
    fields?: Array<TemplateFieldModification>;
    /**
     * 
     * @type {TemplatePatchRules}
     * @memberof TemplatePatch
     */
    rules?: TemplatePatchRules | null;
    /**
     * Whether or not containers created from this template should be automatically approved.
     * @type {boolean}
     * @memberof TemplatePatch
     */
    autoApprove?: boolean | null;
    /**
     * Whether or not containers are automatically removed when recipient email is changed.
     * @type {boolean}
     * @memberof TemplatePatch
     */
    autoRemove?: boolean | null;
    /**
     * Deprecated. Use autoRemove property as replacement.
     * @type {boolean}
     * @memberof TemplatePatch
     */
    autoRevoke?: boolean | null;
}


/**
 * @export
 */
export const TemplatePatchStateEnum = {
    Paused: 'paused',
    Live: 'live'
} as const;
export type TemplatePatchStateEnum = typeof TemplatePatchStateEnum[keyof typeof TemplatePatchStateEnum];


/**
 * Check if a given object implements the TemplatePatch interface.
 */
export function instanceOfTemplatePatch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TemplatePatchFromJSON(json: any): TemplatePatch {
    return TemplatePatchFromJSONTyped(json, false);
}

export function TemplatePatchFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplatePatch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'permissions': !exists(json, 'permissions') ? undefined : ((json['permissions'] as Array<any>).map(PermissionPairFromJSON)),
        'fields': !exists(json, 'fields') ? undefined : ((json['fields'] as Array<any>).map(TemplateFieldModificationFromJSON)),
        'rules': !exists(json, 'rules') ? undefined : TemplatePatchRulesFromJSON(json['rules']),
        'autoApprove': !exists(json, 'autoApprove') ? undefined : json['autoApprove'],
        'autoRemove': !exists(json, 'autoRemove') ? undefined : json['autoRemove'],
        'autoRevoke': !exists(json, 'autoRevoke') ? undefined : json['autoRevoke'],
    };
}

export function TemplatePatchToJSON(value?: TemplatePatch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'state': value.state,
        'permissions': value.permissions === undefined ? undefined : ((value.permissions as Array<any>).map(PermissionPairToJSON)),
        'fields': value.fields === undefined ? undefined : ((value.fields as Array<any>).map(TemplateFieldModificationToJSON)),
        'rules': TemplatePatchRulesToJSON(value.rules),
        'autoApprove': value.autoApprove,
        'autoRemove': value.autoRemove,
        'autoRevoke': value.autoRevoke,
    };
}

