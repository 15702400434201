// Copyright 2024 Merit International Inc. All Rights Reserved

import { Body, Checkbox, Heading, useTheme } from "@merit/frontend-components";
import { Dropzone } from "@src/components/Dropzone/Dropzone";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "@src/components";
import { Image, Pressable, StyleSheet, View } from "react-native";
import { Images } from "@src/utils/Image";
import { ListTemplatesTypeEnum } from "@src/gen/org-portal";
import { useAlertStore } from "@src/stores";
import { v4 as uuidv4 } from "uuid";
import React, { useCallback, useState } from "react";
import _ from "lodash";
import papa from "papaparse";
import type { FileRejection } from "react-dropzone";

type Props = {
  readonly onNext: (data: readonly unknown[], fileName: string, templateType: string) => void;
};

const MAX_ALLOWED_COLUMNS = 42;
const { None, Some } = Helpers;

export const UploadCSV = ({ onNext }: Props) => {
  const { deleteAlert, setAlert } = useAlertStore();
  const { theme } = useTheme();

  const [templateType, setTemplateType] = useState<string | undefined>(ListTemplatesTypeEnum.Merit);

  const styles = StyleSheet.create({
    card: {
      alignItems: "center",
      backgroundColor: theme.colors.background.white,
      borderRadius: theme.borderRadii.s,
      marginTop: theme.spacing.xxl,
      paddingBottom: theme.spacing.xxl,
      paddingHorizontal: theme.spacing.xxl,
      paddingTop: theme.spacing.l,
    },
    container: {
      borderColor: theme.colors.border.default,
      borderRadius: theme.borderRadii.s,
      borderWidth: 1,
      minHeight: 139,
      width: 656,
    },
  });

  const showErrorAlert = useCallback(
    (message: string, title?: string) => {
      setAlert({
        closable: true,
        id: uuidv4(),
        onPressDelete: id => {
          deleteAlert(id);
        },
        size: "medium",
        text: message,
        title,
        type: "error",
      });
    },
    [deleteAlert, setAlert]
  );

  const parseDocument = useCallback(
    (acceptedFiles: readonly File[], fileRejections: readonly FileRejection[]) => {
      if (None(templateType)) {
        showErrorAlert("Please select template type");

        return;
      }

      if (Some(fileRejections)) {
        const uniqueErrors = _.uniq(
          fileRejections.map(({ errors }) => errors.map(error => error.message)).flat()
        );

        uniqueErrors.map(errorMessage => {
          showErrorAlert(errorMessage, "File Upload Failed");
        });
      }

      const headerIndexes = new Map<string, number>();
      const duplicateHeaderNames = new Set<string>();

      acceptedFiles.forEach(file => {
        const reader = new FileReader();
        reader.addEventListener("error", () => {
          showErrorAlert("File reading has failed");
        });

        reader.addEventListener("loadend", () => {
          papa.parse(reader.result as string, {
            complete: (results: papa.ParseResult<readonly unknown[]>) => {
              if (results.errors.length > 0) {
                results.errors.forEach(error => {
                  showErrorAlert(error.message);
                });

                return;
              }

              if (
                Some(results.meta.fields) &&
                results.meta.fields.some(field => field.trim().length === 0)
              ) {
                showErrorAlert(
                  "One or more column headers are empty. Please update the csv file and try again"
                );

                return;
              }

              if (Some(results.meta.fields) && results.meta.fields.length > MAX_ALLOWED_COLUMNS) {
                showErrorAlert(`A maximum of ${MAX_ALLOWED_COLUMNS} columns are allowed`);

                return;
              }

              if (duplicateHeaderNames.size > 0) {
                showErrorAlert(
                  `Duplicate csv header: ${Array.from(duplicateHeaderNames).join(", ")}`
                );

                return;
              }
              onNext(results.data, file.name, templateType);
            },
            delimiter: ",",
            header: true,
            preview: 1,
            // papaparse is supposed to provide a "renamedHeaders" field, but doesn't, so we keep track of duplicates here
            // also, this function is called twice for each header, once with a string index, once with a number,
            // so we convert it to a number just in case
            transformHeader(header: string, index: number): string {
              const numIdx = Number(index);
              if (headerIndexes.has(header)) {
                if (headerIndexes.get(header) !== numIdx) {
                  duplicateHeaderNames.add(header);
                }
              }
              headerIndexes.set(header, numIdx);

              return header;
            },
          });
        });

        reader.readAsText(file);
      });
    },
    [onNext, showErrorAlert, templateType]
  );

  return (
    <View style={{ alignItems: "center" }}>
      <VerticalSpacer size={48} />

      <View>
        <Heading bold level="2">
          What would you like to create?
        </Heading>
        <Body size="l">Select a template to be created.</Body>
        <View style={{ flexDirection: "row" }}>
          <Pressable
            onPress={() => {
              setTemplateType(ListTemplatesTypeEnum.Merit);
            }}
          >
            <View style={styles.card}>
              <Image
                accessibilityLabel="select-document"
                source={Images.merit}
                style={{ height: 160, marginVertical: theme.spacing.xl, width: 280 }}
              />
              <Body size="l">
                <Checkbox
                  defaultChecked={templateType === ListTemplatesTypeEnum.Merit}
                  onChange={value => {
                    setTemplateType(value ? ListTemplatesTypeEnum.Merit : undefined);
                  }}
                />
                <HorizontalSpacer />
                Merit
              </Body>
            </View>
          </Pressable>
          <HorizontalSpacer />
          <Pressable
            onPress={() => {
              setTemplateType(ListTemplatesTypeEnum.Folio);
            }}
          >
            <View style={styles.card}>
              <Image
                accessibilityLabel="select-document"
                source={Images.folio}
                style={{ height: 160, marginVertical: theme.spacing.xl, width: 280 }}
              />
              <Body size="l">
                <Checkbox
                  defaultChecked={templateType === ListTemplatesTypeEnum.Folio}
                  onChange={value => {
                    setTemplateType(value ? ListTemplatesTypeEnum.Folio : undefined);
                  }}
                />
                <HorizontalSpacer />
                Folio
              </Body>
            </View>
          </Pressable>
        </View>
      </View>
      <VerticalSpacer size={48} />

      <View>
        <Heading bold level="2">
          Upload CSV
        </Heading>
        <Body size="l">
          Headers from the file will be used to automatically create a data source and a template.
        </Body>
        <VerticalSpacer size={48} />
        <View style={styles.container}>
          <Dropzone
            acceptedFileTypes={{ "text/csv": [] }}
            isTemplateSelected={Some(templateType)}
            onDrop={parseDocument}
          />
        </View>
      </View>
    </View>
  );
};
