// Copyright 2023 Merit International Inc. All Rights Reserved

import { Animated, Pressable, StyleSheet, View } from "react-native";
import { DatagridBody } from "./DatagridBody";
import { Heading, Icon, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer } from "../Spacer";
import React, { useCallback, useEffect, useState } from "react";
import type { DatagridColumn } from "./types";
import type { OPTestProps } from "../../types/TestProps";

export type DatagridSectionProps<T extends object> = {
  readonly columns: readonly DatagridColumn<T>[];
  readonly data: readonly T[];
  readonly title: string;
  readonly isExpanded?: boolean;
  readonly noDataMessage: string;
  readonly testProps?: OPTestProps;
  readonly testPropsElementIdKey?: keyof T;
  readonly testPropsRowId?: string;
  readonly onToggle: () => void;
};

const { Some } = Helpers;

export function DatagridCollapsableSection<T extends object>({
  columns,
  data,
  isExpanded = false,
  noDataMessage,
  onToggle,
  testProps,
  testPropsElementIdKey,
  testPropsRowId,
  title,
}: DatagridSectionProps<T>) {
  const { theme } = useTheme();
  const [rotateValue] = useState(new Animated.Value(isExpanded ? 0 : 1));

  const styles = StyleSheet.create({
    collapseRow: {
      alignItems: "center",
      borderBottomColor: theme.colors.border.subdued,
      borderBottomWidth: 1,
      flexDirection: "row",
      justifyContent: "flex-start",
      padding: theme.spacing.l,
    },
  });

  const rotate = rotateValue.interpolate({
    inputRange: [0, 1],
    outputRange: ["-180deg", "0deg"],
  });

  const renderAnimation = useCallback(() => {
    const targetValue = isExpanded ? 1 : 0;
    const duration = 200;

    Animated.timing(rotateValue, {
      duration,
      toValue: targetValue,
      useNativeDriver: true,
    }).start();
  }, [isExpanded, rotateValue]);

  useEffect(
    () => () => {
      renderAnimation();
    },
    [renderAnimation]
  );

  return (
    <>
      <Pressable
        onPress={() => {
          renderAnimation();
          onToggle();
        }}
      >
        <View
          style={styles.collapseRow}
          {...Helpers.generateTestIdProps(
            Some(testProps)
              ? {
                  elementId: testPropsRowId,
                  elementName: `${testProps.elementName}RowCollapse`,
                  screenName: testProps.screenName,
                }
              : testProps
          )}
        >
          <HorizontalSpacer />
          <Animated.View style={{ transform: [{ rotate }] }}>
            <Icon name="chevronDownMediumDefault" />
          </Animated.View>
          <HorizontalSpacer size={theme.spacing.s} />
          <Heading
            level="5"
            testProps={
              Some(testProps)
                ? {
                    elementId: testPropsRowId,
                    elementName: `${testProps.elementName}CollapseRowTitle`,
                    screenName: testProps.screenName,
                  }
                : testProps
            }
          >
            {title}
          </Heading>
        </View>
      </Pressable>

      {isExpanded && (
        <View style={{ backgroundColor: theme.colors.surface.subdued }}>
          <DatagridBody
            columns={columns}
            data={data}
            noDataText={noDataMessage}
            testProps={testProps}
            testPropsElementIdKey={testPropsElementIdKey}
          />
        </View>
      )}
    </>
  );
}
