// Copyright 2022 Merit International Inc. All Rights Reserved

import { AccessibilityWidget } from "@merit/frontend-components";
import { AuthProvider } from "@src/components/AuthProvider";
import { ErrorBoundary } from "./utils/ErrorBoundary";
import { Navigator } from "./Router";

export const App = () => (
  <ErrorBoundary>
    <>
      <AccessibilityWidget />
      <AuthProvider>
        <Navigator />
      </AuthProvider>
    </>
  </ErrorBoundary>
);
