// Copyright 2024 Merit International Inc. All Rights Reserved

import { Body, Button, useTheme } from "@merit/frontend-components";
import { Dropzone } from "@src/components/Dropzone/Dropzone";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../components/Spacer";
import { StyleSheet, View } from "react-native";
import { useAlertStore } from "@src/stores";
import { v4 as uuidv4 } from "uuid";
import React, { useCallback, useState } from "react";
import type { Accept, FileRejection } from "react-dropzone";

const { Some } = Helpers;

type Props = {
  readonly onSave: (file: File) => void;
  readonly onCancel: () => void;
  readonly acceptedFileTypes: Accept;
  readonly recommendationText?: string;
};

export const UploadFileModalDropzone = ({
  acceptedFileTypes,
  onCancel,
  onSave,
  recommendationText,
}: Props) => {
  const { theme } = useTheme();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [file, setFile] = useState<File>();
  const { deleteAlert, setAlert } = useAlertStore();

  const pickDocument = useCallback(
    (acceptedFiles: readonly File[], fileRejections: readonly FileRejection[]) => {
      if (fileRejections.length > 0) {
        fileRejections.forEach(rejectedFile => {
          rejectedFile.errors.forEach(error => {
            setAlert({
              closable: true,
              id: uuidv4(),
              onPressDelete: id => {
                deleteAlert(id);
              },
              text: error.message,
              type: "error",
            });
          });
        });

        return;
      }

      setFile(acceptedFiles[acceptedFiles.length - 1]);
      setIsDisabled(false);
    },
    [deleteAlert, setAlert]
  );

  const styles = StyleSheet.create({
    buttonsContainer: {
      alignItems: "center",
      alignSelf: "flex-end",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    container: {
      alignItems: "center",
      borderColor: theme.colors.border.subdued,
      borderWidth: 1,
      justifyContent: "center",
      minHeight: 200,
      minWidth: 456,
    },
  });

  return (
    <>
      <View style={styles.container}>
        <VerticalSpacer size={theme.spacing.s} />
        <View style={{ alignItems: "center" }}>
          <Dropzone
            acceptedFileTypes={acceptedFileTypes}
            fileName={file?.name}
            onDrop={pickDocument}
          />
        </View>
        <VerticalSpacer size={theme.spacing.s} />
        <Body style={{ color: theme.colors.text.subdued }}>{recommendationText}</Body>
      </View>

      <VerticalSpacer />

      <View style={styles.buttonsContainer}>
        <View
          style={{
            flexDirection: "row",
          }}
        >
          <Button
            onPress={() => {
              onCancel();
            }}
            text="Cancel"
            type="secondary"
          />
          <HorizontalSpacer />
          <Button
            disabled={isDisabled}
            onPress={() => {
              if (Some(file)) {
                setIsDisabled(true);
                onSave(file);
              }
            }}
            text="Save"
            type="primary"
          />
        </View>
      </View>
    </>
  );
};
