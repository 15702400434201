/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TemplateFieldColumnMappingPatch } from './TemplateFieldColumnMappingPatch';
import {
    TemplateFieldColumnMappingPatchFromJSON,
    TemplateFieldColumnMappingPatchFromJSONTyped,
    TemplateFieldColumnMappingPatchToJSON,
} from './TemplateFieldColumnMappingPatch';

/**
 * The required inputs to update a data source mapping.
 * @export
 * @interface TemplateDataSourceMappingPatch
 */
export interface TemplateDataSourceMappingPatch {
    /**
     * 
     * @type {string}
     * @memberof TemplateDataSourceMappingPatch
     */
    dataSourceID?: string;
    /**
     * The mappings from column to template field.
     * @type {Array<TemplateFieldColumnMappingPatch>}
     * @memberof TemplateDataSourceMappingPatch
     */
    columnToTemplateField: Array<TemplateFieldColumnMappingPatch>;
}

/**
 * Check if a given object implements the TemplateDataSourceMappingPatch interface.
 */
export function instanceOfTemplateDataSourceMappingPatch(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "columnToTemplateField" in value;

    return isInstance;
}

export function TemplateDataSourceMappingPatchFromJSON(json: any): TemplateDataSourceMappingPatch {
    return TemplateDataSourceMappingPatchFromJSONTyped(json, false);
}

export function TemplateDataSourceMappingPatchFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateDataSourceMappingPatch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataSourceID': !exists(json, 'dataSourceID') ? undefined : json['dataSourceID'],
        'columnToTemplateField': ((json['columnToTemplateField'] as Array<any>).map(TemplateFieldColumnMappingPatchFromJSON)),
    };
}

export function TemplateDataSourceMappingPatchToJSON(value?: TemplateDataSourceMappingPatch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataSourceID': value.dataSourceID,
        'columnToTemplateField': ((value.columnToTemplateField as Array<any>).map(TemplateFieldColumnMappingPatchToJSON)),
    };
}

