// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Heading, Select, useTheme } from "@merit/frontend-components";
import { Tooltip } from "./Tooltip";
import { VerticalSpacer } from "./Spacer";
import { View } from "react-native";
import { useCallback } from "react";
import type { FormValues, Option } from "../screens/ConfigureTemplate/Permissions";
import type { OPTestProps } from "../types/TestProps";

const permissionOptions: readonly { readonly label: string; readonly value: Option }[] = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];

type Props = {
  readonly onSelectOption: (permission: string, value: string) => void;
  readonly values: FormValues;
  readonly testProps: OPTestProps;
  readonly permissionFor: "field" | "policy" | "template";
};

export const SelectPermissions = ({ onSelectOption, permissionFor, testProps, values }: Props) => {
  const { theme } = useTheme();

  const getDefaultValue = useCallback((permissionValue: string) => {
    if (permissionValue === "yes") {
      return permissionOptions[0];
    }

    return permissionOptions[1];
  }, []);

  return (
    <>
      <Heading
        bold
        level="3"
        testProps={{
          ...testProps,
          elementName: `${testProps.elementName}PermissionsText`,
        }}
      >
        Permissions
      </Heading>
      <VerticalSpacer size={theme.spacing.s} />
      <Body>Select the permissions for this {permissionFor}</Body>
      <VerticalSpacer size={theme.spacing.xxl} />
      <View style={{ width: 468 }}>
        <Select
          defaultValue={getDefaultValue(values.readPermission)}
          label="Can others view this?"
          onSelectOption={option => {
            if (typeof option.value === "number") {
              return;
            }

            if (option.value === "no") {
              onSelectOption("extendPermission", option.value);
            }

            onSelectOption("readPermission", option.value);
          }}
          options={permissionOptions}
          rightElement={
            <Tooltip
              icon="informationActive"
              text={`View permissions give organizations the ability to view this ${permissionFor}.`}
            />
          }
          size="large"
          testProps={{
            ...testProps,
            elementName: `${testProps.elementName}ReadPermission`,
          }}
          usePortal
        />
        <VerticalSpacer size={theme.spacing.xxl} />
        <Select
          defaultValue={getDefaultValue(values.extendPermission)}
          disabled={values.readPermission === "no"}
          key={values.readPermission}
          label="Can others use this?"
          onSelectOption={option => {
            if (typeof option.value === "number") {
              return;
            }

            onSelectOption("extendPermission", option.value);
          }}
          options={permissionOptions}
          rightElement={
            <Tooltip
              icon="informationActive"
              text={`Extend permissions give organizations the ability to use and extend this ${permissionFor} to other connected organizations.`}
            />
          }
          size="large"
          testProps={{
            ...testProps,
            elementName: `${testProps.elementName}ExtendPermission`,
          }}
          usePortal
        />
      </View>
    </>
  );
};
