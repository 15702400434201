// Copyright 2022 Merit International Inc. All Rights Reserved

import { AssertIsDefined } from "@src/utils/asserts";
import { Heading } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { PreLoginLayout } from "../layouts/PreLoginLayout";
import { useAlertStore } from "../stores/alertStore";
import { useApi } from "../api/api";
import { useAuthStore } from "../stores/authStore";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useMeritAuth0 } from "../hooks/auth";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useOrgLogoStore } from "@src/stores/orgLogoStore";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useState } from "react";
import type { JwtClaims } from "@src/hooks/loggedInAuthState";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { PreLoginRouteParams, RouteParams } from "../Router";
import type { RouteProp } from "@react-navigation/native";

type Route = RouteProp<PreLoginRouteParams, "LoginSuccessCallback">;
type Navigation = NativeStackNavigationProp<RouteParams, "LoginSuccessCallback">;

const { None, Some } = Helpers;

export const OrgAuthLoginSuccess = () => {
  const meritAuth0 = useMeritAuth0();

  const route = useRoute<Route>();
  const { deleteAlert, setAlert } = useAlertStore();
  const { api } = useApi();
  const { error, error_description } = route.params;
  const {
    accessToken,
    selectedOrgId,
    setAccessToken,
    setPermissions,
    setProfile,
    setSelectedOrgId,
    setSelectedOrgName,
    setSession,
  } = useAuthStore();
  const navigation = useNavigation<Navigation>();
  const [loginError, setLoginError] = useState<string>();
  const [hasFinishedLoggingIn, setHasFinishedLoggingIn] = useState<boolean>(false);
  const { clear: clearLogoStore } = useOrgLogoStore();

  // this flag is required, as sometimes meritAuth0 will populate an accessToken before the selectedOrgId, EVEN in the case where an org has been selected
  // this causes the first useEffect below to be tripped erroneously
  const [loginSuccessReturnedNoOrg, setLoginSuccessReturnedNoOrg] = useState<boolean>(false);
  const client = useLDClient();
  useEffect(() => {
    // this will execute when accessToken from the authStore is initialized (set via `setAccessToken` below)
    // but we don't have an orgID
    // putting this here ensures that the OrgSelect route will be defined in Router.tsx before we navigate to it
    if (Some(accessToken) && None(selectedOrgId) && loginSuccessReturnedNoOrg) {
      navigation.navigate("OrgSelect");
    }
  }, [accessToken, navigation, selectedOrgId, loginSuccessReturnedNoOrg]);

  useEffect(() => {
    const handleCompleteLogin = async (
      newAccessToken: string,
      profile: JwtClaims,
      newSelectedOrgId: string | undefined,
      newSelectedOrgName: string | undefined
    ) => {
      try {
        const resp = await api.orgLoginSuccessRaw({
          accessToken: newAccessToken,
        });
        const respJson = await resp.value();

        setAccessToken(newAccessToken);
        setProfile(profile);
        clearLogoStore();

        const sessionHeader = resp.raw.headers.get("X-Session-Store");
        if (sessionHeader !== null) {
          setSession(sessionHeader);
        }

        if (respJson.orgID === "" || None(respJson.orgID)) {
          // if we got here without an orgID,
          // it means the user entered their credentials, but they did not select an org yet
          // ideally, we would navigate to OrgSelect here,
          // but since the authStore hasn't been populated yet (it will be on re-render), we instead just exit,
          // and let the useEffect above take effect.
          setLoginSuccessReturnedNoOrg(true);

          return;
        }
        setLoginSuccessReturnedNoOrg(false);
        AssertIsDefined(newSelectedOrgId, "No org ID selected, but got org ID in session");
        AssertIsDefined(newSelectedOrgName, "No org ID selected, but got org ID in session");

        setSelectedOrgId(newSelectedOrgId);
        setSelectedOrgName(newSelectedOrgName);
        setPermissions(respJson.permissions);

        await client?.identify({
          kind: "multi",
          org: {
            key: newSelectedOrgId,
          },
          user: {
            key: profile.entityID,
          },
        });

        setHasFinishedLoggingIn(true);
      } catch (err: unknown) {
        if (typeof err === "string") {
          setLoginError(err);
        } else if (err instanceof Error) {
          setLoginError(err.message);
        }
      }
    };

    if (error !== undefined) {
      setAlert({
        closable: true,
        id: uuidv4(),
        onPressDelete: id => {
          deleteAlert(id);
        },
        text: error_description,
        type: "error",
      });
      navigation.navigate("Landing");

      return;
    }
    if (
      meritAuth0.isAuthenticated &&
      meritAuth0.accessToken !== "" &&
      Some(meritAuth0.profile) &&
      !hasFinishedLoggingIn
    ) {
      // eslint-disable-next-line functional/immutable-data
      handleCompleteLogin(
        meritAuth0.accessToken,
        meritAuth0.profile,
        meritAuth0.selectedOrgId,
        meritAuth0.selectedOrgName
      );
    }
  }, [
    api,
    clearLogoStore,
    deleteAlert,
    error,
    error_description,
    meritAuth0.accessToken,
    navigation,
    setAccessToken,
    setAlert,
    setProfile,
    setSession,
    meritAuth0.isLoading,
    meritAuth0.isAuthenticated,
    hasFinishedLoggingIn,
    meritAuth0.profile,
    meritAuth0.selectedOrgId,
    meritAuth0.selectedOrgName,
    setSelectedOrgId,
    setSelectedOrgName,
    client,
    setPermissions,
  ]);

  useEffect(() => {
    if (hasFinishedLoggingIn) {
      navigation.navigate("OrgSettings");
    }
  }, [hasFinishedLoggingIn, navigation]);

  return (
    <PreLoginLayout>
      {loginError !== undefined && (
        <Heading level="3">
          There was an error logging in, please refresh the page and try again
        </Heading>
      )}
    </PreLoginLayout>
  );
};
