// Copyright 2023 Merit International Inc. All Rights Reserved.

import { create } from "zustand";

export type AppConstants = {
  readonly datasourcesLimit: number;
  readonly templatesLimit: number;
  readonly sharedTemplatesLimit: number;
  readonly recordsLimit: number;
  readonly approvalsLimit: number;
  readonly folioInboxLimit: number;
  readonly fieldsLimit: number;
  readonly sharedFieldsLimit: number;
  readonly fieldMappingFolioLimit: number;
  readonly adminsLimit: number;
  readonly policiesLimit: number;
  readonly agentsLimit: number;
  readonly folioFieldNames: {
    readonly organizationName: string;
    readonly issuingOrgName: string;
  };
  readonly meritFieldNames: {
    readonly firstName: string;
    readonly lastName: string;
    readonly email: string;
    readonly issuingOrgName: string;
  };
  readonly accountFolioFieldNames: {
    readonly name: string;
    readonly description: string;
    readonly website: string;
    readonly addressLine1: string;
    readonly addressLine2: string;
    readonly addressCity: string;
    readonly addressState: string;
    readonly addressZipCode: string;
    readonly addressCountry: string;
    readonly phoneNumber: string;
    readonly timeZone: string;
    readonly logo: string;
    readonly governingCountry: string;
    readonly governingState: string;
    readonly governmentIDNumber: string;
    readonly governmentIDType: string;
    readonly governmentLegalName: string;
  };
};

export const useAppConstantsStore = create<AppConstants>()(() => ({
  accountFolioFieldNames: {
    addressCity: "City",
    addressCountry: "Country",
    addressLine1: "Address Line 1",
    addressLine2: "Address Line 2",
    addressState: "State",
    addressZipCode: "Zip Code",
    description: "Org Description",
    governingCountry: "Governing Country",
    governingState: "Governing State",
    governmentIDNumber: "Government ID Number",
    governmentIDType: "Government ID Type",
    governmentLegalName: "Org Legal Name",
    logo: "Org Logo",
    name: "Organization Name",
    phoneNumber: "Org Phone Number",
    timeZone: "Timezone",
    website: "Org Website",
  },
  adminsLimit: 25,
  agentsLimit: 100,
  approvalsLimit: 100,
  datasourcesLimit: 10,
  fieldMappingFolioLimit: 20,
  fieldsLimit: 10,
  folioFieldNames: {
    issuingOrgName: "Issuing Org Name",
    organizationName: "Organization Name",
  },
  folioInboxLimit: 100,
  meritFieldNames: {
    email: "Email",
    firstName: "First Name",
    issuingOrgName: "Issuing Org Name",
    lastName: "Last Name",
  },
  policiesLimit: 100,
  recordsLimit: 100,
  sharedFieldsLimit: 10,
  sharedTemplatesLimit: 10,
  templatesLimit: 10,
}));
