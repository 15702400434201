// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Button, Heading, Icon, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../../components/Spacer";
import { Pressable, StyleSheet, View } from "react-native";
import { getTemplateName } from "@src/utils/getTemplatename";
import type { ListTemplates200Response } from "@src/gen/org-portal";
import type { OPTestProps } from "../../types/TestProps";
import type { Rule } from "./EditPolicy";

const { Some } = Helpers;

export type Template = {
  readonly name?: string;
  readonly id: string;
};

type Props = {
  readonly rule: Rule;
  readonly onPressAdditionIcon: () => void;
  readonly removeTemplate: (templateID: string) => void;
  readonly ruleIndex: number;
  readonly onRemove: () => void;
  readonly testProps: OPTestProps;
  readonly templatesList: ListTemplates200Response["templates"];
};

export const PolicyRule = ({
  onPressAdditionIcon,
  onRemove,
  removeTemplate,
  rule,
  ruleIndex,
  templatesList,
  testProps,
}: Props) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    divider: {
      alignItems: "center",
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      marginVertical: theme.spacing.xxl,
    },
    dividerElement: {
      backgroundColor: theme.colors.border.default,
      flex: 1,
      height: 1,
    },
    dividerText: {
      borderColor: theme.colors.border.default,
      borderRadius: theme.borderRadii.s,
      borderWidth: 1,
      marginHorizontal: theme.spacing.xl,
      paddingHorizontal: 2,
    },
  });

  return (
    <View key={rule.id}>
      <VerticalSpacer size={ruleIndex === 0 ? 26 : theme.spacing.xs} />
      {ruleIndex !== 0 && (
        <>
          <View style={styles.divider}>
            <View style={styles.dividerElement} />
            <View style={styles.dividerText}>
              <Heading
                level="6"
                testProps={{
                  ...testProps,
                  elementName: `${testProps.elementName}AndText`,
                }}
              >
                AND
              </Heading>
            </View>
            <View style={styles.dividerElement} />
          </View>
          <VerticalSpacer size={30} />
        </>
      )}

      <View style={{ flexDirection: "row" }}>
        <Heading
          level="4"
          testProps={{
            ...testProps,
            elementId: ruleIndex.toString(),
            elementName: `${testProps.elementName}RuleNumber`,
          }}
        >
          Rule {ruleIndex + 1}
        </Heading>
        <HorizontalSpacer size={6} />
        <Pressable onPress={onRemove} style={{ justifyContent: "center" }}>
          <Icon
            name="closeExtraSmallDefault"
            testProps={{
              ...testProps,
              elementId: ruleIndex.toString(),
              elementName: `${testProps.elementName}RemoveRule`,
            }}
          />
        </Pressable>
      </View>
      <VerticalSpacer size={28} />
      {rule.arguments?.length === 1 && (
        <View style={{ alignItems: "center", flexDirection: "row" }}>
          <Body>Has an active merit from template</Body>
          <HorizontalSpacer size={theme.spacing.s} />
          <Button
            iconLeft="addSmallDefault"
            onPress={() => {
              onPressAdditionIcon();
            }}
            shape="circle"
            size="small"
            testProps={{
              ...testProps,
              elementId: ruleIndex.toString(),
              elementName: `${testProps.elementName}AddRule`,
            }}
            type="secondary"
          />
        </View>
      )}

      {rule.arguments?.slice(1).map((templateID, index) => (
        <View key={templateID}>
          <View style={{ alignItems: "center", flexDirection: "row" }}>
            <Body>Has an active merit from template</Body>
            <HorizontalSpacer size={theme.spacing.s} />
            <Button
              disabled={Some(rule.arguments) && rule.arguments.length >= 4}
              iconLeft="addSmallDefault"
              onPress={() => {
                onPressAdditionIcon();
              }}
              shape="circle"
              size="small"
              testProps={{
                ...testProps,
                elementId: index.toString() + templateID,
                elementName: `${testProps.elementName}AddRule`,
              }}
              type="secondary"
            />
          </View>
          <VerticalSpacer size={theme.spacing.m} />

          <View style={{ alignItems: "center", flexDirection: "row" }}>
            <Heading
              level="4"
              testProps={{
                ...testProps,
                elementId: index.toString() + templateID,
                elementName: `${testProps.elementName}TemplateName`,
              }}
            >
              {/** When we have more than 100 templates, the names are not displayed.
               * Currently, we don't have the ability to retrieve template names by IDs.
               * A solution needs to be provided by the platform."  */}
              {getTemplateName(templateID, templatesList) ?? templateID}
            </Heading>
            <HorizontalSpacer size={theme.spacing.s} />
            <Pressable
              onPress={() => {
                removeTemplate(templateID);
              }}
            >
              <Icon
                name="closeExtraSmallDefault"
                testProps={{
                  ...testProps,
                  elementId: index.toString() + templateID,
                  elementName: `${testProps.elementName}RemoveRule`,
                }}
              />
            </Pressable>
          </View>
          <VerticalSpacer size={theme.spacing.xl} />
          <Heading
            color={index === 2 ? theme.colors.text.disabled : theme.colors.text.link}
            level="5"
            testProps={{
              ...testProps,
              elementId: index.toString() + templateID,
              elementName: `${testProps.elementName}OrText`,
            }}
          >
            + OR
          </Heading>
          <VerticalSpacer size={27} />
        </View>
      ))}
    </View>
  );
};
