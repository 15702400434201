// Copyright 2024 Merit International Inc. All Rights Reserved

import "reactflow/dist/style.css";
import { Button, Heading, useTheme } from "@merit/frontend-components";
import {
  CreateDatasourceRequestSchemaColumnsInnerColumnDataTypeEnum as ColumnDataTypeEnum,
  ListTemplatesTypeEnum,
  GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerActionEnum as PermissionsActionEnum,
  SearchRequestObjectTypeEnum,
} from "@src/gen/org-portal";
import { ConfirmationModal } from "@src/components/Modals";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, Spin, VerticalSpacer } from "@src/components";
import { MappingFlow, NODE_HEIGHT } from "./MappingFlow";
import { NULL_UUID } from "../../../screens/Fields/ExtendField";
import { NamesForm } from "./NamesForm";
import { Position } from "reactflow";
import { StyleSheet, View } from "react-native";
import { firstNameMatchers, lastNameMatchers } from "../matcher";
import { useAlertStore, useAppConstantsStore } from "../../../stores";
import { useApi } from "../../../api/api";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLoadedConfigurationState } from "@src/hooks/useLoadedConfigurationState";
import { useLoggedInAuthState } from "@src/hooks/loggedInAuthState";
import { useNavigation } from "@react-navigation/native";
import { usePreviewDataSourceStyles } from "../Datasource/styles";
import { useServerErrorHandler } from "@src/utils/useServerErrorHandler";
import { v4 as uuidv4 } from "uuid";
import type { AsyncFormData, FormFields } from "./NamesForm";
import type {
  CreateTemplateFieldsRequestInner,
  GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner,
  GetFieldKind200Response,
  MapTemplateRequestTemplateFieldMapInner,
  OrgsGet200ResponseContainersInner,
} from "@src/gen/org-portal";
import type { FormValues as DataSource } from "../Datasource/PreviewDataSource";
import type { Edge, Node } from "reactflow";
import type { FormikProps } from "formik";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { NodeData, TemplateField } from "./MappingFlow";
import type { RouteParams } from "@src/Router";

const { None, Some } = Helpers;

const defaultPermissions = [
  {
    action: PermissionsActionEnum.Read,
    permissibleToPermit: {
      action: PermissionsActionEnum.Read,
      grantedToName: "None",
    },
    permitted: { action: PermissionsActionEnum.Read, grantedToName: "None" },
  },
  {
    action: PermissionsActionEnum.Extend,
    permissibleToPermit: { action: PermissionsActionEnum.Extend, grantedToName: "None" },
    permitted: { action: PermissionsActionEnum.Extend, grantedToName: "None" },
  },
];

type Props = {
  readonly dataSource: DataSource;
  readonly onBack: () => void;
  readonly templateType: string;
};

type AllFieldKinds = {
  readonly available: readonly CreateTemplateFieldsRequestInner[];
  readonly notAvailable: readonly CreateTemplateFieldsRequestInner[];
  readonly edges: readonly Edge[];
};

type Navigation = NativeStackNavigationProp<RouteParams, "Datasources">;

export const MappingView = ({ dataSource, onBack, templateType }: Props) => {
  const { theme } = useTheme();
  const { selectedOrgId } = useLoggedInAuthState();
  const { configuration } = useLoadedConfigurationState();
  const { folioFieldNames, meritFieldNames } = useAppConstantsStore();
  const dsStyles = usePreviewDataSourceStyles();
  const { api } = useApi();
  const { errorHandler } = useServerErrorHandler();
  const navigation = useNavigation<Navigation>();
  const { deleteAlert, setAlert } = useAlertStore();
  const [formValues, setFormValues] = useState<FormFields>({
    datasourceName: dataSource.name,
    templateName: dataSource.name,
  });

  const formRef = useRef<FormikProps<FormFields>>(null);

  const styles = StyleSheet.create({
    container: {
      alignItems: "center",
      flex: 1,
      paddingHorizontal: 24,
    },
    content: {
      flex: 1,
      width: 960,
    },
  });

  const baseFields = useMemo(
    () =>
      templateType === ListTemplatesTypeEnum.Merit
        ? Object.values(meritFieldNames)
        : Object.values(folioFieldNames),
    [folioFieldNames, meritFieldNames, templateType]
  );

  const isFirstNameSettled = useRef(false);
  const isLastNameSettled = useRef(false);
  const isEmailSettled = useRef(false);

  const getBaseFieldName = useCallback(
    (name: string, matchers: readonly string[], baseField: string, setMatched: () => void) => {
      const hasBaseField = dataSource.schemaColumns.some(
        ds => ds.columnName.toLowerCase() === baseField.toLowerCase()
      );

      // First preference is "first name" or "last name"
      if (hasBaseField) {
        if (name.toLowerCase() === baseField.toLowerCase()) {
          setMatched();

          return baseField;
        }

        return name;
      }

      if (matchers.includes(name.toLowerCase())) {
        setMatched();

        return baseField;
      }

      return name;
    },
    [dataSource.schemaColumns]
  );

  const getFieldName = useCallback(
    (name: string) => {
      const isFirstName = firstNameMatchers.includes(name.toLowerCase());
      if (isFirstName) {
        if (isFirstNameSettled.current) {
          return name;
        }

        return getBaseFieldName(name, firstNameMatchers, meritFieldNames.firstName, () => {
          // eslint-disable-next-line functional/immutable-data
          isFirstNameSettled.current = true;
        });
      }

      const isLastName = lastNameMatchers.includes(name.toLowerCase());
      if (isLastName) {
        if (isLastNameSettled.current) {
          return name;
        }

        return getBaseFieldName(name, lastNameMatchers, meritFieldNames.lastName, () => {
          // eslint-disable-next-line functional/immutable-data
          isLastNameSettled.current = true;
        });
      }

      if (name.toLowerCase() === meritFieldNames.email.toLowerCase()) {
        if (isEmailSettled.current) {
          return name;
        }
        // eslint-disable-next-line functional/immutable-data
        isEmailSettled.current = true;

        return meritFieldNames.email;
      }

      return name;
    },
    [getBaseFieldName, meritFieldNames]
  );

  const generatedTemplateFields = useMemo(
    () =>
      dataSource.schemaColumns.map(ds => ({
        contact: false,
        displayName: getFieldName(ds.columnName),
        error: undefined,
        fieldKindID: ds.id,
        name: ds.columnName,
        type: ds.columnDataType,
      })),
    [dataSource.schemaColumns, getFieldName]
  );

  const initialTemplateFields = useMemo(
    () =>
      generatedTemplateFields.map((field, index) => ({
        ...field,
        error:
          generatedTemplateFields.findIndex(
            f => f.displayName.toLowerCase() === field.displayName.toLowerCase()
          ) === index
            ? undefined
            : "Template field with above name already exist",
        isBaseField: baseFields.includes(field.displayName),
      })),
    [baseFields, generatedTemplateFields]
  );

  const asyncData = {
    error: undefined,
    previousName: undefined,
  };

  const initialAsyncDataOfNamesForm = {
    datasourceName: asyncData,
    templateName: asyncData,
  };

  const [templateFields, setTemplateFields] =
    useState<readonly TemplateField[]>(initialTemplateFields);
  const [templateNodes, setTemplateNodes] = useState<readonly Node<NodeData>[]>([]);
  const [edges, setEdges] = useState<readonly Edge[]>([]);
  const [baseFieldKinds, setBaseFieldKinds] = useState<readonly GetFieldKind200Response[]>([]);
  const [orgFieldKinds, setOrgFieldKinds] = useState<readonly GetFieldKind200Response[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [accountFolio, setAccountFolio] = useState<OrgsGet200ResponseContainersInner>();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [asyncDataOfNamesForm, setAsyncDataOfNamesForm] = useState<AsyncFormData>(
    initialAsyncDataOfNamesForm
  );

  const fetchBaseFieldKinds = useCallback(async () => {
    try {
      const res = await api.getFieldKinds({
        limit: 100,
        orgID: selectedOrgId,
        ownerID: configuration.solUUID,
        parentID: NULL_UUID,
      });
      setBaseFieldKinds(res.fieldKinds);
    } catch (error) {
      errorHandler(error);
    }
  }, [api, configuration.solUUID, errorHandler, selectedOrgId]);

  const getOrgFieldKinds = useCallback(async () => {
    try {
      const res = await api.getFieldKinds({
        limit: 100,
        orgID: selectedOrgId,
      });
      setOrgFieldKinds(res.fieldKinds);
    } catch (error) {
      errorHandler(error);
    }
  }, [api, errorHandler, selectedOrgId]);

  const fetchAccountFolio = useCallback(async () => {
    const accountFolioResponse = await api.getContainers({
      orgID: selectedOrgId,
      recipientID: selectedOrgId,
      templateID: configuration.accountFolioTemplateUUID,
      templateType: "Folio",
    });

    if (Some(accountFolioResponse.containers) && accountFolioResponse.containers.length > 0) {
      setAccountFolio(accountFolioResponse.containers[0]);
    }
  }, [api, configuration.accountFolioTemplateUUID, selectedOrgId]);

  useEffect(() => {
    const generateNodes = () => {
      const templateNames = templateFields.map(template => template.displayName.toLowerCase());
      const templatesNodeHeader = {
        displayName: "Template fields",
        isBaseField: false,
        name: "Template fields",
        type: undefined,
      };
      const generatedTemplateNodes = [templatesNodeHeader, ...templateFields].map(
        (field, index) => ({
          data: {
            dataType: field.type,
            isBaseField: field.isBaseField,
            label: field.displayName,
            name: field.name,
            onEdit: (fieldName: string, error: string | undefined) => {
              const updated = templateFields.map(fd => {
                if (fd.name === field.name) {
                  return {
                    ...fd,
                    displayName: fieldName,
                    error,
                  };
                }

                return fd;
              });
              setTemplateFields(updated);
            },
            templateNames: templateNames.filter(
              (fieldName, position) =>
                !(fieldName === field.displayName.toLowerCase() && position === index - 1)
            ),
            type: "source",
          },
          id: `id-${index}`,
          position: { x: 460, y: index * NODE_HEIGHT },
          targetPosition: Position.Left,
          type: index === 0 ? "labelNode" : "editableFieldNode",
        })
      );

      setTemplateNodes(generatedTemplateNodes);
    };

    generateNodes();
  }, [dataSource.schemaColumns, templateFields]);

  useEffect(() => {
    setEdges(prev =>
      prev.map(edge => {
        const field = templateNodes.find(node => edge.source === node.id);

        if (Some(field)) {
          return {
            ...edge,
            sourceHandle: field.data.label,
          };
        }

        return edge;
      })
    );
  }, [templateNodes]);

  useEffect(() => {
    fetchBaseFieldKinds();
    getOrgFieldKinds();
    fetchAccountFolio();
  }, [fetchAccountFolio, fetchBaseFieldKinds, getOrgFieldKinds]);

  const handleSubmit = () => {
    setIsConfirmationModalOpen(true);
  };

  const getAccountFolioField = (fieldName: string) => {
    if (None(accountFolio)) {
      throw new Error("Attempted to update mapping with no account folio");
    }

    return accountFolio.fields?.find(f => f.name === fieldName);
  };

  const checkForDuplicateNames = useCallback(
    async (objectName: string, objectType: SearchRequestObjectTypeEnum) => {
      if (objectName.trim().length < 2) {
        if (objectType === SearchRequestObjectTypeEnum.Template) {
          setAsyncDataOfNamesForm(prev => ({
            ...prev,
            templateName: {
              error: undefined,
              previousName: objectName,
            },
          }));
        }
        if (objectType === SearchRequestObjectTypeEnum.Datasource) {
          setAsyncDataOfNamesForm(prev => ({
            ...prev,
            datasourceName: {
              error: undefined,
              previousName: objectName,
            },
          }));
        }

        return;
      }
      try {
        setIsFetching(true);
        const response = await api.search({
          orgID: selectedOrgId,
          query: {
            objectName,
            objectType,
          },
        });

        if (objectType === SearchRequestObjectTypeEnum.Template) {
          const template = response.templates?.find(
            ({ name }) => name?.toLowerCase() === objectName.toLowerCase()
          );

          setAsyncDataOfNamesForm(prev => ({
            ...prev,
            templateName: {
              error: Some(template)
                ? `Template named '${template.name ?? ""}' already exists`
                : undefined,
              previousName: objectName,
            },
          }));
        }
        if (objectType === SearchRequestObjectTypeEnum.Datasource) {
          const ds = response.dataSources?.find(
            ({ name }) => name.toLowerCase() === objectName.toLowerCase()
          );

          setAsyncDataOfNamesForm(prev => ({
            ...prev,
            datasourceName: {
              error: Some(ds) ? `Data source named '${ds.name}' already exists` : undefined,
              previousName: objectName,
            },
          }));
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsFetching(false);
      }
    },
    [api, errorHandler, selectedOrgId]
  );

  useEffect(() => {
    if (Some(formRef.current)) {
      checkForDuplicateNames(
        formRef.current.values.templateName,
        SearchRequestObjectTypeEnum.Template
      );
      checkForDuplicateNames(
        formRef.current.values.templateName,
        SearchRequestObjectTypeEnum.Datasource
      );
    }
  }, [checkForDuplicateNames]);

  const getIssuingOrgMappingColumn = (
    fieldsToMap:
      | readonly GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner[]
      | undefined
  ) => {
    const issuingOrgNameField = fieldsToMap?.find(f => f.name === folioFieldNames.issuingOrgName);
    if (None(issuingOrgNameField) || None(issuingOrgNameField.fieldID)) {
      throw new Error("No issuing org name field on template");
    }

    if (None(accountFolio)) {
      throw new Error("Attempted to update mapping with no account folio");
    }

    const accountFolioField = getAccountFolioField(folioFieldNames.organizationName);

    if (None(accountFolioField)) {
      throw new Error("Could not find field on account folio");
    }

    return {
      containerFieldID: accountFolioField.templateFieldID,
      containerID: accountFolio.id,
      templateFieldID: issuingOrgNameField.fieldID,
    };
  };

  const displayAlert = (message: string) => {
    setAlert({
      id: uuidv4(),
      onPressDelete: () => undefined,
      text: message,
      type: "error",
    });
  };

  const validateBaseFields = () => {
    const baseFieldNames = baseFields.map(name => name.toLowerCase());
    const templateBaseFields = templateFields.filter(field =>
      baseFieldNames.includes(field.displayName.toLowerCase())
    );

    if (templateType === ListTemplatesTypeEnum.Merit) {
      if (templateBaseFields.length !== 3) {
        displayAlert(
          "Base fields(First Name, Last Name, Email) are missing. please check and try again"
        );

        return false;
      }
    }

    if (
      templateType === ListTemplatesTypeEnum.Folio &&
      None(
        templateBaseFields.find(
          field =>
            field.displayName.toLowerCase() === folioFieldNames.organizationName.toLowerCase()
        )
      )
    ) {
      displayAlert("Base field(Organization Name) is missing. please check and try again");

      return false;
    }

    const baseFieldDataTypeErrors = templateBaseFields.reduce<readonly string[]>(
      (errors, field) => {
        const fieldName = field.displayName.toLowerCase();
        if (templateType === ListTemplatesTypeEnum.Merit) {
          if (fieldName === "first name" && field.type !== ColumnDataTypeEnum.Text) {
            return [
              ...errors,
              `The data type of 'First Name' should be '${ColumnDataTypeEnum.Text}' but got '${field.type}'`,
            ];
          }
          if (fieldName === "last name" && field.type !== ColumnDataTypeEnum.Text) {
            return [
              ...errors,
              `The data type of 'Last Name' should be '${ColumnDataTypeEnum.Text}' but got '${field.type}'`,
            ];
          }
          if (fieldName === "email" && field.type !== ColumnDataTypeEnum.Email) {
            return [
              ...errors,
              `The data type of 'Email' should be '${ColumnDataTypeEnum.Email}' but got '${field.type}'`,
            ];
          }
        }

        if (templateType === ListTemplatesTypeEnum.Folio) {
          if (
            fieldName === folioFieldNames.organizationName.toLowerCase() &&
            field.type !== ColumnDataTypeEnum.Text
          ) {
            return [
              ...errors,
              `The data type of ${folioFieldNames.organizationName} should be '${ColumnDataTypeEnum.Text}' but got '${field.type}'`,
            ];
          }
        }

        return errors;
      },
      []
    );

    if (baseFieldDataTypeErrors.length > 0) {
      baseFieldDataTypeErrors.forEach(errorMessage => {
        displayAlert(errorMessage);
      });

      return false;
    }

    return true;
  };

  const createAndMap = async (values: FormFields) => {
    if (!validateBaseFields()) {
      return;
    }

    try {
      const allFieldsMapped = edges.length !== templateFields.length;
      if (allFieldsMapped) {
        displayAlert("Please make sure all fields are mapped");

        return;
      }

      setIsLoading(true);

      const allFieldKinds: AllFieldKinds = {
        available: [],
        edges: [],
        notAvailable: [],
      };
      const payloads = edges.reduce<AllFieldKinds>((prev, edge) => {
        const baseMeritTemplateFieldNames = baseFields.map(_ => _.toLowerCase());
        const orgFieldKind = orgFieldKinds.find(
          _ => _.name.toLowerCase() === edge.sourceHandle?.toLowerCase()
        );
        const schemaField = templateFields.find(
          templateField => templateField.name.toLowerCase() === edge.targetHandle?.toLowerCase()
        );
        const baseFieldKindID = baseFieldKinds.find(
          baseFieldKind => baseFieldKind.dataType === schemaField?.type
        );
        if (
          Some(edge.sourceHandle) &&
          !baseMeritTemplateFieldNames.includes(edge.sourceHandle.toLowerCase())
        ) {
          const orgFieldKindDataType = orgFieldKind?.dataType;
          if (Some(orgFieldKind)) {
            if (orgFieldKindDataType === schemaField?.type) {
              return {
                ...prev,
                available: [
                  ...prev.available,
                  {
                    baseFieldKindID: orgFieldKind.fieldKindID,
                    contact: false,
                    fieldKindID: orgFieldKind.fieldKindID,
                    method: "AddTemplateFieldProperties",
                    name: orgFieldKind.name,
                    type: orgFieldKind.dataType,
                  },
                ],
                edges: [...prev.edges, edge],
              };
            }

            const fieldName = `${orgFieldKind.name}-${schemaField?.type ?? ""}`;
            const checkFieldsWithNewFieldName = orgFieldKinds.find(_ => _.name === fieldName);
            if (Some(checkFieldsWithNewFieldName)) {
              return {
                ...prev,
                available: [
                  ...prev.available,
                  {
                    baseFieldKindID: checkFieldsWithNewFieldName.fieldKindID,
                    contact: false,
                    fieldKindID: checkFieldsWithNewFieldName.fieldKindID,
                    method: "AddTemplateFieldProperties",
                    name: checkFieldsWithNewFieldName.name,
                    type: checkFieldsWithNewFieldName.dataType,
                  },
                ],
                edges: [...prev.edges, { ...edge, sourceHandle: checkFieldsWithNewFieldName.name }],
              };
            }

            if (baseFieldKindID === undefined) {
              return { ...prev, edges: [...prev.edges, edge] };
            }

            return {
              ...prev,
              edges: [...prev.edges, { ...edge, sourceHandle: fieldName }],
              notAvailable: [
                ...prev.notAvailable,
                {
                  baseFieldKindID: baseFieldKindID.fieldKindID,
                  contact: false,
                  fieldKindID: baseFieldKindID.fieldKindID,
                  name: fieldName,
                  type: baseFieldKindID.dataType,
                },
              ],
            };
          }

          if (baseFieldKindID === undefined) {
            return { ...prev, edges: [...prev.edges, edge] };
          }

          return {
            ...prev,
            edges: [...prev.edges, edge],
            notAvailable: [
              ...prev.notAvailable,
              {
                baseFieldKindID: baseFieldKindID.fieldKindID,
                contact: false,
                fieldKindID: baseFieldKindID.fieldKindID,
                name: edge.sourceHandle,
                type: baseFieldKindID.dataType,
              },
            ],
          };
        }

        return { ...prev, edges: [...prev.edges, edge] };
      }, allFieldKinds);

      //  create fields
      const fieldsPromises = payloads.notAvailable.map(fieldKind =>
        api.extendFieldKind({
          extendFieldKindRequest: {
            description: "",
            extendPermission: "All",
            name: fieldKind.name ?? "",
            readPermission: "All",
            ruleValid: [],
            shareExtendPermission: "All",
            shareReadPermission: "All",
          },
          fieldKindID: fieldKind.fieldKindID,
          orgID: selectedOrgId,
        })
      );
      const fieldKindResults = await Promise.all(fieldsPromises);

      const mapTemplateFieldsPayload = fieldKindResults.map(fieldKindResult => ({
        contact: false,
        description: fieldKindResult.fieldKind?.name,
        fieldKindID: fieldKindResult.fieldKind?.fieldKindID ?? "",
        method: "AddTemplateFieldProperties",
        name: fieldKindResult.fieldKind?.name,
      }));

      // template creation
      const templateResponse = await api.createTemplate({
        extendTemplateRequest: {
          description: "",
          name: values.templateName,
          permissions: defaultPermissions,
        },
        orgID: selectedOrgId,
        templateID:
          templateType === ListTemplatesTypeEnum.Merit
            ? configuration.baseMeritTemplateUUID
            : configuration.baseFolioTemplateUUID,
      });

      const datasourceResponse = await api.createDatasource({
        datasource: {
          ...dataSource,
          name: values.datasourceName,
          schemaColumns: dataSource.schemaColumns.map(schemaColumn => {
            if (schemaColumn.columnDataType === "PhoneNumber") {
              return {
                ...schemaColumn,
                columnPhoneNumberDefaultCountryCode: schemaColumn.format,
              };
            }

            if (
              schemaColumn.columnDataType === "Date" ||
              schemaColumn.columnDataType === "DateTime"
            ) {
              return {
                ...schemaColumn,
                columnDateFormat: schemaColumn.format,
              };
            }

            return schemaColumn;
          }),
        },
        orgID: selectedOrgId,
      });

      if (mapTemplateFieldsPayload.length > 0 || payloads.available.length > 0) {
        // add fields to template
        await api.editTemplate(
          {
            editTemplateRequest: {},
            orgID: selectedOrgId,
            templateID: templateResponse.id ?? "",
          },
          // Since swagger is not generating the proper types and request body due to discriminator type.
          // So had to pass the body explicitly
          {
            body: {
              fields: [...mapTemplateFieldsPayload, ...payloads.available],
            } as unknown as FormData,
          }
        );
      }

      const templateDetails = await api.getTemplate({
        orgID: selectedOrgId,
        templateID: templateResponse.id ?? "",
      });

      const templateFieldMap = payloads.edges.reduce<
        readonly MapTemplateRequestTemplateFieldMapInner[]
      >((prev, edge) => {
        const templateField = templateDetails.templateFields?.find(
          _ => _.name?.toLowerCase() === edge.sourceHandle?.toLowerCase()
        );
        const schemaColumn = datasourceResponse.schemaColumns?.find(
          _ => _.columnName.toLowerCase() === edge.targetHandle?.toLowerCase()
        );
        if (Some(templateField) && Some(schemaColumn)) {
          return [
            ...prev,
            {
              columnID: schemaColumn.id ?? "",
              templateFieldID: templateField.fieldID ?? "",
            },
          ];
        }

        return prev;
      }, []);

      await api.mapTemplate({
        datasourceID: Some(datasourceResponse.id) ? datasourceResponse.id.toString() : "",
        orgID: selectedOrgId,
        templateID: templateResponse.id ?? "",
        templateMappingRequest: {
          templateFieldMap: [
            ...templateFieldMap,
            getIssuingOrgMappingColumn(templateDetails.templateFields),
          ],
        },
      });
      await api.editTemplate({
        editTemplateRequest: {
          state: "live",
        },
        orgID: selectedOrgId,
        templateID: templateResponse.id ?? "",
      });

      setAlert({
        closable: true,
        id: uuidv4(),
        onPressDelete: id => {
          deleteAlert(id);
        },
        text: "Auto map has been completed successfully",
        type: "success",
      });

      if (Some(datasourceResponse.id)) {
        navigation.navigate("DatasourceDetails", {
          id: datasourceResponse.id.toString(),
        });
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateFormValues = useCallback((name: string, value: string) => {
    setFormValues(prev => ({ ...prev, [name]: value }));
  }, []);

  const hasErrors =
    Some(asyncDataOfNamesForm.templateName.error) ||
    Some(asyncDataOfNamesForm.datasourceName.error) ||
    templateFields.some(field => Some(field.error));

  return (
    <>
      <Spin spinning={isLoading}>
        <View style={styles.container}>
          <View style={styles.content}>
            <VerticalSpacer size={theme.spacing.xxl} />
            <Heading bold level="3">
              Data source and template mapping
            </Heading>
            <VerticalSpacer size={theme.spacing.xxl} />

            <View style={{ minHeight: 128 }}>
              <Spin size={20} spinning={isFetching}>
                <NamesForm
                  asyncData={asyncDataOfNamesForm}
                  checkForDuplicateNames={checkForDuplicateNames}
                  datasourceName={dataSource.name}
                  formRef={formRef}
                  onSubmit={values => {
                    createAndMap(values);
                  }}
                  templateName={dataSource.name}
                  updateFormValues={updateFormValues}
                />
              </Spin>
            </View>

            {templateNodes.length > 0 && (
              <MappingFlow
                dataSource={dataSource}
                edges={edges}
                templateFieldsLength={templateFields.length}
                templateNodes={templateNodes}
                updateEdges={setEdges}
              />
            )}
          </View>
        </View>
        <View style={dsStyles.footer}>
          <Button disabled={isLoading} onPress={onBack} text="Back" type="secondary" />
          <HorizontalSpacer />
          <Button
            disabled={
              isLoading ||
              isFetching ||
              hasErrors ||
              formValues.datasourceName.trim().length === 0 ||
              formValues.templateName.trim().length === 0
            }
            onPress={handleSubmit}
            text="Submit"
          />
        </View>
      </Spin>

      {isConfirmationModalOpen && (
        <ConfirmationModal
          buttonText="ok"
          onClose={() => {
            setIsConfirmationModalOpen(false);
          }}
          onOk={() => {
            formRef.current?.handleSubmit();
            setIsConfirmationModalOpen(false);
          }}
          text="This will create a template, fields, a datasource, and map the datasource columns to template fields."
          title="Are you sure you want to submit?"
        />
      )}
    </>
  );
};
